<template>
  <div class="product-modal-block">
    <div class="product-modal-content">
      <div class="product-detail-close-block">
        <a href="javascript:void(0);" class="pdc-icon" @click="pdcClose"><img src="../../../assets/images/product-detail-close.svg" alt=""></a>
      </div>
      <div class="product-detail-content-block-wrapper">
        <div class="product-detail-content-block">
          <div class="pdc-left">
            <div class="pdc-left-mobile-productname">
              <div class="pdc-brand fs-24 fw-500">
                <p>Jean size: {{ localFullSize }}</p>
              </div>
              <h2 class="pdc-name fs-24 fw-700">{{ localProductName }}</h2>
            </div>
            <div class="pdc-image-gallery">
              <div class="pdc-img-single" v-for="productImg in localProductImages" :key="productImg">
                <img :src="`${baseUrl}${productImg}`" alt="" />
              </div>
            </div>
          </div>
          <div class="pdc-right">
            <div class="pdc-brand fs-24 fw-500">
              <p>Jean size: {{ localFullSize }}</p>
            </div>
            <h2 class="pdc-name fs-24 fw-700">{{ localProductName }}</h2>
            <!-- <div class="pdc-size fs-24">
              <p>Your Size: {{ localFullSize }} {{ localId }}</p>
            </div> -->
            <!-- <div v-html="isBookmarkBtn"></div> -->
            <div class="pdc-cta d-flex flex-wrap w-100" :class="`pdc-cta-${localId}`" v-html="localIsBookmarkBtn"></div>
            <div class="pdc-text-block">
              <div class="pdc-product-details">
                <h3 class="fs-24 fw-600">Product Details</h3>
                <div class="pdc-product-details-text fs-16">
                  <p>{{ localProductDesc }}</p>
                </div>
                <div class="rise-stretch-block">
                  <div class="rise-stretch-item">
                    <div class="pdcsf-icon">
                      <img src="../../../assets/images/high-rise-icon.svg" alt="" />
                    </div>
                    <div class="pdcsf-text">
                      <p>{{ localRiseInProduct < 3 ? "Low Rise" : localRiseInProduct == 3 ? "Mid Rise" : localRiseInProduct > 3 ? "High Rise" : "Low Rise" }} </p>
                    </div>
                    <div class="pdcsf-indicator">
                      <div class="pdcsf-indicator-block">
                        <div class="pdfcsf-item pdfcsf-item-1" :class="{ 'active': localRiseInProduct == 1 }"></div>
                        <div class="pdfcsf-item pdfcsf-item-2" :class="{ 'active': localRiseInProduct == 2 }"></div>
                        <div class="pdfcsf-item pdfcsf-item-3" :class="{ 'active': localRiseInProduct == 3 }"></div>
                        <div class="pdfcsf-item pdfcsf-item-4" :class="{ 'active': localRiseInProduct == 4 }"></div>
                        <div class="pdfcsf-item pdfcsf-item-5" :class="{ 'active': localRiseInProduct == 5 }"></div>
                      </div>
                    </div>
                  </div>
                  <div class="rise-stretch-item">
                    <div class="pdcsf-icon">
                      <img src="../../../assets/images/low-stretch-icon.svg" alt="" />
                    </div>
                    <div class="pdcsf-text">
                      <p>{{ localStretch < 3 ? "Low Stretch" : localStretch == 3 ? "Mid Stretch" : localStretch > 3 ? "High Stretch" : "Low Stretch" }}</p>
                    </div>
                    <div class="pdcsf-indicator">
                      <div class="pdcsf-indicator-block">
                        <div class="pdfcsf-item pdfcsf-item-1" :class="{ 'active': localStretch == 1 }"></div>
                        <div class="pdfcsf-item pdfcsf-item-2" :class="{ 'active': localStretch == 2 }"></div>
                        <div class="pdfcsf-item pdfcsf-item-3" :class="{ 'active': localStretch == 3 }"></div>
                        <div class="pdfcsf-item pdfcsf-item-4" :class="{ 'active': localStretch == 4 }"></div>
                        <div class="pdfcsf-item pdfcsf-item-5" :class="{ 'active': localStretch == 5 }"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pdc-size-fit">
                  <h4 class="fs-18 fs-600">Size & Fit Notes</h4>
                  <div class="pdc-size-fit-single">
                    <div class="pdcsf-icon">
                      <img src="../../../assets/images/pdc-waist-icon.svg" alt="" />
                    </div>
                    <div class="pdcsf-text">
                      <p>Waist: {{ localWaistNotes }}</p>
                    </div>
                  </div>
                  <div class="pdc-size-fit-single">
                    <div class="pdcsf-icon">
                      <img src="../../../assets/images/pdc-hips-icon.svg" alt="" />
                    </div>
                    <div class="pdcsf-text">
                      <p>Hips: {{ localHipNotes }}</p>
                    </div>
                  </div>
                  <div class="pdc-size-fit-single">
                    <div class="pdcsf-icon">
                      <img src="../../../assets/images/pdc-thigh-icon.svg" alt="" />
                    </div>
                    <div class="pdcsf-text">
                      <p>Thigh: {{ localThighNotes }}</p>
                    </div>
                  </div>
                  <div class="pdc-size-fit-single">
                    <div class="pdcsf-icon">
                      <img src="../../../assets/images/pdc-inseam-icon.svg" alt="" />
                    </div>
                    <div class="pdcsf-text">
                      <p>Inseam: {{ localInseamNotes || "Normal" }}</p>
                    </div>
                  </div>
                  <!-- <div class="pdc-size-fit-single">
                    <div class="pdcsf-icon">
                      <img src="../../../assets/images/pdc-size-icon.svg" alt="">
                    </div>
                    <div class="pdcsf-text">
                      <p>Size worn by the model: S</p>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="pdc-text-block">
              <h3 class="fs-24 fw-600">Disclaimer</h3>
              <div class="accordion-disclaimer">
                <div class="accordion-item active">
                  <div class="accordion-header">{{ disclaimer.size }}</div>
                  <div class="accordion-content" v-html="disclaimer.sizeDesc"></div>
                </div>
                <div class="accordion-item">
                  <div class="accordion-header">{{ disclaimer.pricing }}</div>
                  <div class="accordion-content" v-html="disclaimer.pricingDesc"></div>
                </div>
                <div class="accordion-item">
                  <div class="accordion-header">{{ disclaimer.wash }}</div>
                  <div class="accordion-content" v-html="disclaimer.washDesc"></div>
                </div>
                <div class="accordion-item">
                  <div class="accordion-header">
                    {{ disclaimer.availability }}
                  </div>
                  <div class="accordion-content" v-html="disclaimer.availabilityDesc"></div>
                </div>
                <div class="accordion-item">
                  <div class="accordion-header">
                    {{ disclaimer.returnPolicy }}
                  </div>
                  <div class="accordion-content" v-html="disclaimer.returnPolicyDesc"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <the-trigger @triggerIntersected="otherProducts"></the-trigger>
        <div class="other-matches-block" v-if="showRelatedProducts">
          <h2 class="fs-48 fw-600">Other fit matches</h2>
          <div class="rom-list-block related-products-block d-flex flex-wrap w-100">
            <div class="goldie-product-block" v-for="otherProduct in otherProductsArr" :key="otherProduct._id"
              @click="openThisProduct(otherProduct._id)">
              <div class="gpb-content">
                <a href="javascript:void(0);" class="gpb-content-link">
                  <img :src="`${baseUrl}${otherProduct.productImages[0]}`" alt=""
                    class="goldie-product-list-thumbnail" />
                </a>
                <div class="gpb-content-bottom">
                  <a href="javascript:void(0);">
                    <div class="gpb-content-row-1 d-flex flex-wrap w-100">
                      <div class="gpb-brand">
                        <p class="fs-18">
                          Jean size: {{ otherProduct.fullSize }}
                        </p>
                      </div>
                    </div>
                    <div class="gpb-content-row-2 d-flex flex-wrap w-100">
                      <div class="gpb-product-name">
                        <h3 class="fs-22 fw-600">
                          {{ otherProduct.productName }}
                        </h3>
                      </div>
                    </div>
                    <!-- <div class="gpb-content-row-3 d-flex flex-wrap w-100">
                      <div class="gpb-product-size">
                        <p class="fs-16">Full size: {{ otherProduct.fullSize }}</p>
                      </div>
                    </div> -->
                  </a>
                  <a href="javascript:void(0);" :id="`${otherProduct._id}-heart-other`" class="gpb-wishlist"
                    :class="{ 'active-wishlist': otherProduct.isBookmark }"
                    @click="bookmarkFavOtherProduct(otherProduct._id)"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// packages
import $ from "jquery";
import axios from "axios";
import _ from "lodash";

// components
import TheTrigger from "./TheTrigger.vue";

export default {
  components: {
    TheTrigger
  },
  props: [
    "productName",
    "productImages",
    "brandName",
    "fullSize",
    "productUrl",
    "productDesc",
    "waistNotes",
    "hipNotes",
    "thighNotes",
    "inseamNotes",
    "riseInProduct",
    "stretch",
    "isBookmark",
    "id",
    "isBookmarkBtn",
    "relatedProducts",
  ],
  data() {
    return {
      disclaimer: {},
      showRelatedProducts: true,
      otherProductsArr: [],
      localBrandName: this.brandName,
      localIsBookmarkBtn: this.isBookmarkBtn,
      localProductName: this.productName,
      localProductImages: this.productImages,
      localFullSize: this.fullSize,
      localProductUrl: this.productUrl,
      localProductDesc: this.productDesc,
      localId: this.id,
      localRiseInProduct: this.riseInProduct,
      localStretch: this.stretch,
      localWaistNotes: this.waistNotes,
      localHipNotes: this.hipNotes,
      localThighNotes: this.thighNotes,
      localInseamNotes: this.inseamNotes,
      localIsBookmark: this.isBookmark,
        baseUrl: process.env.VUE_APP_BASE_URL
      }
  },
  watch: {
    brandName(newVal) {
      this.localBrandName = newVal;
    },
    isBookmarkBtn(newVal) {
      this.localIsBookmarkBtn = newVal;
    },
    productName(newVal) {
      this.localProductName = newVal;
    },
    productImages(newVal) {
      this.localProductImages = newVal;
    },
    fullSize(newVal) {
      this.localFullSize = newVal;
    },
    productUrl(newVal) {
      this.localProductUrl = newVal;
    },
    productDesc(newVal) {
      this.localProductDesc = newVal;
    },
    id(newVal) {
      this.localId = newVal;
    },
    riseInProduct(newVal) {
      this.localRiseInProduct = newVal;
    },
    stretch(newVal) {
      this.localStretch = newVal;
    },
    waistNotes(newVal) {
      this.localWaistNotes = newVal;
    },
    hipNotes(newVal) {
      this.localHipNotes = newVal;
    },
    thighNotes(newVal) {
      this.localThighNotes = newVal;
    },
    inseamNotes(newVal) {
      this.localInseamNotes = newVal;
    },
    isBookmark(newVal) {
      this.localIsBookmark = newVal;
      }
  },
  computed: {
    isAuth: function () {
      return this.$store.getters.isAuth;
    },
    getMeasurementData: function () {
      return this.$store.getters["products/getMeasurementFromLocal"];
    },
    getToken: function () {
      return this.$store.getters.getToken;
      }
  },
  methods: {
    otherProducts: async function () {
      this.showRelatedProducts = true;
      if (
        !this.getMeasurementData ||
        !this.getMeasurementData.waist ||
        !this.getMeasurementData.hip ||
        !this.getMeasurementData.thigh ||
        !this.getMeasurementData.inseam
      ) {
        this.$swal({
          position: "top",
          icon: false,
          title: false,
          html: "Please submit your measurement form first.",
          showConfirmButton: false,
          timer: 1900,
        });
        setTimeout(() => {
          this.$router.push("/");
        }, 1700);
        return;
      }

      // Call api for next 3
      // const getToken = this.$store.getters.getToken;
      if (this.otherProductsArr.length === 0) {
        if (this.relatedProducts && this.relatedProducts.length) {
          // other 3 logic
          const threeProducts = this.nextThreeProducts(this.id)
          this.otherProductsArr = threeProducts;
        } else {
          this.showRelatedProducts = false;
        }
      }
      return;
    },
    bookmarkFavOtherProduct: async function (_id) {
      const isAuth = this.$store.getters.isAuth;
      if (isAuth) {
        let getToken = this.$store.getters.getToken;
        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/account/bookmark/fav/${_id}`,
          {},
          {
            headers: {
              Authorization: "Bearer " + getToken,
            },
          }
        );
        const bookmarkRes = response.data.message;
        if (bookmarkRes == "Saved!") {
          $(`#${_id}-heart`).toggleClass("active-wishlist");
          $(`#${_id}-heart-other`).toggleClass("active-wishlist");
          return;
        } else {
          $(`#${_id}-heart`).toggleClass("active-wishlist");
          $(`#${_id}-heart-other`).toggleClass("active-wishlist");
          return;
        }
      } else {
        this.$swal({
          position: "top",
          icon: false,
          title: false,
          html: "Please login to bookmark a product.",
          showConfirmButton: false,
          timer: 1900,
        });
        this.pdcClose();
        $("body").addClass("noscroll");
        $(".popup-signup-login").css({ display: "flex" });
        $(".signup-form-popup, #forgotpassword-form-popup").hide();
        $("#login-form-popup").show();
        return;
      }
    },
    openThisProduct: async function (_id) {
      const headers = this.getToken
        ? { Authorization: "Bearer " + this.getToken }
        : {};
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/product/detail`,
        {
          productId: _id,
          waist: this.getMeasurementData.waist,
          hip: this.getMeasurementData.hip,
          thigh: this.getMeasurementData.thigh,
          inseam: this.getMeasurementData.inseam,
        },
        {
          headers,
        }
      );
      if (response && response.status === 200 && response.data.code === 200) {
        const productDetail = response.data.data.detail;
        const productNotes = response.data.data.fitNotes;

        this.localBrandName = productDetail.brand.name;
        this.localIsBookmarkBtn = productDetail.isBookmark
          ? `<button class="fg-btn-primary fg-btn-primary-blue" @click="showNow(${productDetail.productUrl})">
                <span>Shop Now</span>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.3545 0C12.3545 1.26859 13.4545 3.16691 14.5665 4.76118C15.9985 6.81377 17.7075 8.6069 19.6685 9.97595C21.1375 11.0011 22.9215 11.9852 24.3545 11.9852M12.3545 24C12.3545 22.7314 13.4545 20.8331 14.5665 19.2388C15.9985 17.1863 17.7075 15.3931 19.6685 14.0241C21.1375 12.9989 22.9215 12.0148 24.3545 12.0148M24.3545 12H0.354492"
                    stroke="white" stroke-width="2" />
                </svg>
              </button>
              <a href="javascript:void(0);" id="${productDetail._id}-heart-pdc" class="pdc-wishlist heart-pdc active-wishlist-pdc ${productDetail._id}" @click="bookmarkFav(${productDetail._id})">`
          : `<button class="fg-btn-primary fg-btn-primary-blue" @click="showNow(${productDetail.productUrl})">
                <span>Shop Now</span>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.3545 0C12.3545 1.26859 13.4545 3.16691 14.5665 4.76118C15.9985 6.81377 17.7075 8.6069 19.6685 9.97595C21.1375 11.0011 22.9215 11.9852 24.3545 11.9852M12.3545 24C12.3545 22.7314 13.4545 20.8331 14.5665 19.2388C15.9985 17.1863 17.7075 15.3931 19.6685 14.0241C21.1375 12.9989 22.9215 12.0148 24.3545 12.0148M24.3545 12H0.354492"
                    stroke="white" stroke-width="2" />
                </svg>
              </button><a href="javascript:void(0);" class="pdc-wishlist heart-pdc ${productDetail._id}" id="${productDetail._id}-heart-pdc" @click="bookmarkFav(${productDetail._id})">`;
        this.localProductName = productDetail.productName;
        this.localProductImages = productDetail.productImages || [];
        this.localFullSize = productDetail.fullSize;
        this.localProductUrl = productDetail.productUrl;
        this.localProductDesc = productDetail.productDesc;
        this.localId = productDetail._id;
        this.localRiseInProduct = productDetail.rise;
        this.localStretch = productDetail.stretch;
        this.localWaistNotes = productNotes.waistFitNotes;
        this.localHipNotes = productNotes.hipFitNotes;
        this.localThighNotes = productNotes.thighFitNotes;
        this.localInseamNotes = productNotes.inseamFitNotes;
        this.localIsBookmark = productDetail.isBookmark;
        $(".product-modal-content").scrollTop(0); // Scroll to the top
        window.history.pushState({}, '', `${process.env.VUE_APP_BASE_URL}/product/detail/${productDetail.slug}`);
        if (this.relatedProducts && this.relatedProducts.length) {
          // getting next 3 products..
          const threeProducts = this.nextThreeProducts(_id)
          this.otherProductsArr = threeProducts;
        } else {
          this.showRelatedProducts = false;
        }
        return;
      } else {
        this.$swal({
          position: "top",
          icon: false,
          title: false,
          html: "Something went wrong.",
          showConfirmButton: false,
          timer: 1900,
        });
        setTimeout(() => {
          window.location.href = "/";
        }, 1700);
        return;
      }
    },
    checkForReturningUser: async function () {
      let getToken = this.$store.getters.getToken;
      if (getToken) {
        const checkSent = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/feedback/check/sent`,
          {
            userId: this.$store.getters.getUserId,
          },
          {
            headers: {
              Authorization: "Bearer " + getToken,
            },
          }
        );

        if (
          checkSent &&
          checkSent.status === 200 &&
          checkSent.data.code == 200
        ) {
          const finResponse = checkSent.data.data;
          if (!finResponse.feedbackThere) {
            localStorage.setItem("visitedBefore", true);
            // call api to save this
            const isAuth = this.$store.getters.isAuth;
            if (isAuth) {
              await axios.post(
                `${process.env.VUE_APP_API_BASE_URL}/feedback/send?pending=1`,
                {
                  email: this.loggedInEmail,
                },
                {
                  headers: {
                    Authorization: "Bearer " + getToken,
                  },
                }
              );
            }
          }
        }
      }
      return;
    },
    clickCapture: async function (id) {
      let getToken = this.$store.getters.getToken;
      let headers = {};
      if (getToken) {
        headers = { Authorization: "Bearer " + getToken };
      }
      await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/clickcapture/add`,
        {
          productId: id,
        },
        { headers }
      );
      return;
    },
    nextThreeProducts: function (id) {
      const index = _.findIndex(this.relatedProducts, { _id: id });

      let matchedThreeRelatedProducts = [];

      if (this.relatedProducts.length > 1) {  // Ensure there are other products to show
        let count = 0;
        let i = 1;
        while (count < 3 && i < this.relatedProducts.length) {
          const nextIndex = (index + i) % this.relatedProducts.length;
          if (nextIndex !== index) {
            // Exclude the clicked product
            matchedThreeRelatedProducts.push(this.relatedProducts[nextIndex]);
            count++;
          }
          i++;
        }
      }
      return matchedThreeRelatedProducts
    },
    showNow: function (productUrl) {
      this.checkForReturningUser();
      this.clickCapture(this.id);
      window.open(productUrl, "_blank");
      return;
    },
    bookmarkFav: async function (id) {
      const isAuth = this.$store.getters.isAuth;
      if (isAuth) {
        let getToken = this.$store.getters.getToken;
        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/account/bookmark/fav/${id}`,
          {},
          {
            headers: {
              Authorization: "Bearer " + getToken,
            },
          }
        );
        const bookmarkRes = response.data.message;
        if (bookmarkRes == "Saved!") {
          // $(document).on('.pdc-wishlist', 'click', function () {
          //   if ($(`#${id}`).hasClass(".heart-pdc")) {
          //     $(`#${id}`).addClass('active-wishlist');
          //   } else {
          //     alert("not there");
          //   }
          // })
          // $(`.heart-pdc`).addClass('active-wishlist-pdc');
          // $(`#${id}-heart`).addClass('active-wishlist');
          // alert('saved');
          // $(`#${id}`).toggleClass('active-wishlist');
          return;
        } else {
          // alert("Removed");
          // $(`#${this.id}`).toggleClass('active-wishlist');
          // $(`.heart-pdc`).removeClass('active-wishlist-pdc');
          // $(`#${id}-heart`).removeClass('active-wishlist');
          // alert('removed');
          return;
        }
      } else {
        return this.$swal({
          position: "top",
          icon: false,
          title: false,
          html: "Please login to bookmark a product.",
          showConfirmButton: false,
          timer: 1900,
        });
      }
    },
    pdcClose: function () {
      // alert()
      $(".pdc-cta").html("");
      // this.showRelatedProducts = false;
      this.otherProductsArr = [];
      this.localBrandName = this.brandName;
      this.localIsBookmarkBtn = this.isBookmarkBtn;
      this.localProductName = this.productName;
      this.localProductImages = this.productImages;
      this.localFullSize = this.fullSize;
      this.localProductUrl = this.productUrl;
      this.localProductDesc = this.productDesc;
      this.localId = this.id;
      this.localRiseInProduct = this.riseInProduct;
      this.localStretch = this.stretch;
      this.localWaistNotes = this.waistNotes;
      this.localHipNotes = this.hipNotes;
      this.localThighNotes = this.thighNotes;
      this.localInseamNotes = this.inseamNotes;
      this.localIsBookmark = this.isBookmark;
      window.history.pushState({}, '', `${process.env.VUE_APP_BASE_URL}/products`);
      $(".product-modal-content").removeClass("open");
      $(".fg-navigation").removeClass("reduceindex");
      //$('.measurements-form-overlay').hide();
      $("body").removeClass("noscroll");
      $(".product-modal-content").scrollTop(0); // Scroll to the top
    },
  },
  async mounted() {
    // <button class="fg-btn-primary fg-btn-primary-blue" @click="showNow(productUrl)" >
    //           <span>Shop Now {{ isBookmark }}</span>
    //           <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //             <path
    //               d="M12.3545 0C12.3545 1.26859 13.4545 3.16691 14.5665 4.76118C15.9985 6.81377 17.7075 8.6069 19.6685 9.97595C21.1375 11.0011 22.9215 11.9852 24.3545 11.9852M12.3545 24C12.3545 22.7314 13.4545 20.8331 14.5665 19.2388C15.9985 17.1863 17.7075 15.3931 19.6685 14.0241C21.1375 12.9989 22.9215 12.0148 24.3545 12.0148M24.3545 12H0.354492"
    //               stroke="white" stroke-width="2" />
    //           </svg>
    //         </button >
    //   <a href="javascript:void(0);" : id="`${this.id}-heart-pdc`" class="pdc-wishlist heart-pdc" : class="{'active-wishlist-pdc': isBookmark}" @click="bookmarkFav(this.id)" >
    //         </a >
    /*Product details block - related products slider on mobile*/
    if ($(window).width() < 767) {
      $(".related-products-block").slick({
        infinite: true,
        adaptiveHeight: true,
        arrows: false, // Disable arrows
        autoplay: false,
        autoplaySpeed: 1000, // Set the interval between slide transitions in milliseconds
        cssEase: "ease-in-out",
        slidesToShow: 1,
        dots: false,
      });
    }
    /*Product details block - related products slider on mobile*/

    /*Product details block - Goldies top picks slider mobile screens*/
    if ($(window).width() < 767) {
      $(".goldies-top-picks-block").slick({
        infinite: true,
        adaptiveHeight: true,
        arrows: false, // Disable arrows
        autoplay: false,
        autoplaySpeed: 1000, // Set the interval between slide transitions in milliseconds
        cssEase: "ease-in-out",
        slidesToShow: 1,
        dots: false,
      });
      $(".btn-filter").on("click", function () {
        $(".fg-results-filter-block").css("transform", "translateX(0)");
        $(".measurements-form-overlay").fadeIn(100);
        $("body").addClass("noscroll");
      });
      $(".filter-block-close").on("click", function () {
        $(".fg-results-filter-block").css("transform", "translateX(-100%)");
        $(".measurements-form-overlay").fadeOut(100);
        $("body").removeClass("noscroll");
      });
    }
    /*Product details block - Goldies top picks slider mobile screens*/

    // Diclaimer Accordians
    const accordionItems = document.querySelectorAll(".accordion-item");

    accordionItems.forEach((item) => {
      const header = item.querySelector(".accordion-header");
      header.addEventListener("click", () => {
        // Close all sections
        accordionItems.forEach((otherItem) => {
          if (otherItem !== item) {
            otherItem.classList.remove("active");
          }
        });
        // Toggle current section
        item.classList.toggle("active");
      });
    });

    $(document).on("click", ".fg-btn-primary-blue", () => {
      this.showNow(this.productUrl);
      return;
    });

    $(document).on("click", ".pdc-wishlist", async () => {
      if (this.isAuth) {
        var idHtml = $(".pdc-wishlist").attr("id");
        var id = idHtml.split("-")[0];
        if (id == "undefined") {
          return;
        }
        $(".heart-pdc").toggleClass("active-wishlist-pdc");
        $(`#${id}-heart`).toggleClass("active-wishlist");
        await this.bookmarkFav(id);
        // alert(id)
        if ($(".heart-pdc").hasClass("active-wishlist-pdc")) {
          return this.$swal({
            position: "top",
            icon: false,
            title: false,
            html: "Saved as favorite",
            showConfirmButton: false,
            timer: 1900,
          });
        } else {
          return this.$swal({
            position: "top",
            icon: false,
            title: false,
            html: "Removed from favorites",
            showConfirmButton: false,
            timer: 1900,
          });
        }
      } else {
        this.$swal({
          position: "top",
          icon: false,
          title: false,
          html: "Please signup/login to bookmark a product.",
          showConfirmButton: false,
          timer: 1900,
        });
        this.pdcClose();
        $("body").addClass("noscroll");
        $(".popup-signup-login").css({ display: "flex" });
        $(".signup-form-popup, #forgotpassword-form-popup").hide();
        $("#login-form-popup").show();
        return;
      }
    });

    $(document).on(
      "click",
      ".goldie-product-block a:not(.gpb-wishlist)",
      function () {
        $(".product-modal-content").addClass("open");
        $(".fg-navigation").addClass("reduceindex");
        //$('.measurements-form-overlay').show();
        $("body").addClass("noscroll");
      }
    );

    const disclaimerResponse = await axios.get(
      `${process.env.VUE_APP_API_BASE_URL}/common/disclaimer`
    );
    this.disclaimer = disclaimerResponse.data.data;
    }
  }
</script>

<style></style>
