<template>
  <section class="measurement-sec-top about-page-sec-top partner-page-top">
    <!-- <img src="assets/images/how-goldie-works-banner-bg.svg" alt="" class="how-goldie-works-banner-img"> -->
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="home-banner-form search-measurements-block" :class="{ 'home-banner-email': showEmailInput }">
            <div class="text-box text-box-small">
              <label :class="{ 'active': waist }" for="waistSection">Waist<sup>*</sup></label>
              <input id="waistSection" type="text" autocomplete="off" v-model="waist" />
              <span v-for="error in v$.waist.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
            </div>
            <div class="text-box text-box-small">
              <label :class="{ 'active': hip }" for="hipsSection">Hips<sup>*</sup></label>
              <input id="hipsSection" type="text" autocomplete="off" v-model="hip" />
              <span v-for="error in v$.hip.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
            </div>
            <div class="text-box text-box-small">
              <label :class="{ 'active': thigh }" for="thighSection">Thigh<sup>*</sup></label>
              <input id="thighSection" type="text" autocomplete="off" v-model="thigh" />
              <span v-for="error in v$.thigh.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
            </div>
            <div class="text-box text-box-small">
              <label :class="{ 'active': inseam }" for="inseamSection">Inseam<sup>*</sup></label>
              <input id="inseamSection" type="text" autocomplete="off" v-model="inseam" />
              <span v-for="error in v$.inseam.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
            </div>
            <div class="text-box text-box-large" v-if="showEmailInput">
              <label :class="{ 'active': email }" for="emailBanner">Email<sup>*</sup></label>
              <input id="emailBanner" type="text" autocomplete="off" v-model="email" />
              <span v-for="error in v$.email.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
            </div>
            <div class="home-form-cta">
              <button type="submit" class="fg-btn-primary w-100" @click="submitMeasurementForm">
                <span v-if="measurementThere">Update</span>
                <span v-else>Calculate</span>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 0.333496C10 1.39065 10.9167 2.97259 11.8434 4.30114C13.0367 6.01164 14.4608 7.50591 16.095 8.64678C17.3192 9.50107 18.8058 10.3211 20 10.3211M10 20.3335C10 19.2763 10.9167 17.6944 11.8434 16.3658C13.0367 14.6554 14.4608 13.1611 16.095 12.0202C17.3192 11.1659 18.8058 10.3458 20 10.3458M20 10.3335H0"
                    stroke="#0F2C66" stroke-width="2" />
                </svg>
              </button>
            </div>
          </div>
          <div class="measurement-btn-block">
            <a href="javascript:void(0);" class="measurement-btn">
              <span v-if="isLoggedIn">Measurements </span>
              <span v-else>Let's find your perfect fit! </span>
              <svg class="arrow-icon" width="24" height="25" viewBox="0 0 24 25" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M6 9.22363L12 15.2236L18 9.22363" stroke="#F2C840" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </a>
          </div>
          <div class="about-banner-block">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="45" viewBox="0 0 17 45" fill="none"
              class="about-banner-icon about-banner-icon-1">
              <path
                d="M8.17656 0.0429688L8.6452 10.3724C9.20223 15.7423 11.2709 20.2439 15.7018 21.1234L16.7779 21.337L15.7018 21.5506C11.2709 22.4301 9.20223 26.5046 8.6452 31.8744L8.17656 44.3776L7.70792 31.8744C7.15089 26.5046 5.50699 22.2166 1.07604 21.337L0 21.1234L1.07604 20.9099C5.50699 20.0303 7.15089 15.7423 7.70792 10.3725L8.17656 0.0429688Z"
                fill="white" />
              <animate attributeName="opacity" dur="2s" values="0.2;1;0.2" repeatCount="indefinite" begin="0.1">
              </animate>
            </svg>
            <h1 class="fs-40 fw-400 text-white" v-html="bannerTitle"></h1>
          </div>
          <div class="partner-banner-graphic">
            <!-- <img :src="`${baseUrl}${bannerImage}`" alt="" class="partner-banner-graphic-img"> -->
            <div class="partner-banner-graphic-img" id="partner-banner-graphic-img"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import axios from "axios";
  import lottie from 'lottie-web';
  import { useVuelidate } from '@vuelidate/core';
  import { required, helpers, between, email } from '@vuelidate/validators';
  // props: [],
  export default {
    props: ['bannerTitle', 'bannerImage'],
    data () {
      return {
        v$: useVuelidate(),
        waist: "",
        hip: "",
        thigh: "",
        inseam: "",
        baseUrl: process.env.VUE_APP_BASE_URL,
        email: "",
        showEmailInput: true,
        measurementThere: false
      }
    },
    validations() {
      return {
        email: {
          required: helpers.withMessage('Email is required.', required),
          email: helpers.withMessage('Invalid email address.', email),
        },
        waist: this.waistValidation,
        hip: this.hipValidation,
        thigh: this.thighValidation,
        inseam: this.inseamValidation
      }
    },
    computed: {
      loggedInEmail: function () {
        return this.$store.getters.getEmail;
      },
      isLoggedIn: function() {
        return this.$store.getters.isAuth;
      },
      measurementType: function () {
        return localStorage.getItem("measurementType");
      },
      waistValidation: function () {
        if (this.measurementType === "inches") { // inches
          return {
            required: helpers.withMessage('Waist is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 99))
          };
        } else { // cms
          return {
            required: helpers.withMessage('Waist is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 161))
          };
        }
      },
      hipValidation: function () {
        if (this.measurementType === "inches") { // inches
          return {
            required: helpers.withMessage('Hip is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 99))
          };
        } else { // cms
          return {
            required: helpers.withMessage('Hip is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 161))
          };
        }
      },
      thighValidation: function () {
        if (this.measurementType === "inches") { // inches
          return {
            required: helpers.withMessage('Thigh is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 99))
          };
        } else { // cms
          return {
            required: helpers.withMessage('Thigh is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 161))
          };
        }
      },
      inseamValidation: function () {
        if (this.measurementType === "inches") { // inches
          return {
            required: helpers.withMessage('Inseam is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 99))
          };
        } else { // cms
          return {
            required: helpers.withMessage('Inseam is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 161))
          };
        }
      }
    },
    methods: {
      loadAnimation() {
      // Ensure the element exists before attempting to load the animation
      const container = document.getElementById('partner-banner-graphic-img');
      if (!container) {
        console.log('Element with id "partner-banner-graphic-img" not found.');
        return;
      }

      lottie.loadAnimation({
        container: container,
        renderer: 'svg',
        autoplay: true,
        loop: false,
        path: 'animations/partner.json', // Path to Lottie animation JSON file
      });
      },
      submitMeasurementForm: async function () {
        this.v$.$touch();
        const validateForm = await this.v$.$validate();
        if (!validateForm) return;
        this.$store.dispatch('products/measurementFromUser', {
          waist: this.waist,
          hip: this.hip,
          thigh: this.thigh,
          inseam: this.inseam,
          email: this.email,
          measurementType: this.measurementType ? this.measurementType : "inches"
        });
        this.$router.push('/products');

        if (this.isLoggedIn == false) {
          // call api for measurement
          await axios.post(`${process.env.VUE_APP_API_BASE_URL}/measurement/save`, {
            waist: this.waist,
            hip: this.hip,
            thigh: this.thigh,
            inseam: this.inseam,
            email: this.email
          });
        }
        return;
      }
    },
    mounted() {
      this.loadAnimation();
      let measurement = this.$store.getters['products/getMeasurementFromLocal'];
      this.waist = measurement.waist;
      this.hip = measurement.hip;
      this.thigh = measurement.thigh;
      this.inseam = measurement.inseam;
      this.email = measurement.email;

      if (this.waist && this.hip && this.thigh && this.inseam) {
        this.measurementThere = true;
      } else {
        this.measurementThere = false;
      }

      if (this.isLoggedIn) {
        this.showEmailInput = false;
      }
    }
  }
</script>

<style></style>
