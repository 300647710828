<template>
  <div class="measurements-form-overlay"></div>
  <the-measurement></the-measurement>
  <!-- <section class="account-sec-top">
    <div class="container">
      <div class="row">
        <div class="col-12">&nbsp;</div>
      </div>
    </div>
  </section> -->
  <section class="account-sec-1 sec-nav-blur">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="fs-48 fw-600">Account Settings</h1>
          <!-- Your Name -->
          <form @submit.prevent="submitNameChangeForm">
            <div class="account-block d-flex flex-wrap w-100">
              <div class="account-block-title d-flex flex-wrap w-100">
                <h3 class="fs-18 fw-600">Your Name</h3>
                <a href="javascript:void(0);" class="change-link change-name-link">Change</a>
              </div>
              <div class="account-block-content account-block-content-2">
                <div class="account-block-form d-flex flex-wrap w-100">
                  <div class="home-banner-form d-flex flex-wrap w-100">
                    <div class="text-box">
                      <label for="nameProfile" class="active">Name<sup>*</sup></label>
                      <input id="nameProfile" type="text" v-model="name" disabled />
                      <span v-for="error in v$.name.$errors" :key="error.uid" class="label-error">{{ error.$message
                        }}</span>
                    </div>
                  </div>
                </div>
                <div class="account-block-cta change-name-cta">
                  <button type="submit" class="fg-btn-primary fg-btn-darkblue">
                    <span>Update</span>
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10 0.333496C10 1.39065 10.9167 2.97259 11.8434 4.30114C13.0367 6.01164 14.4608 7.50591 16.095 8.64678C17.3192 9.50107 18.8058 10.3211 20 10.3211M10 20.3335C10 19.2763 10.9167 17.6944 11.8434 16.3658C13.0367 14.6554 14.4608 13.1611 16.095 12.0202C17.3192 11.1659 18.8058 10.3458 20 10.3458M20 10.3335H0"
                        stroke="#fff" stroke-width="2" />
                    </svg>
                  </button>
                </div>
              </div>

            </div>
          </form>
          <!-- Your Name -->
          <!-- Password  -->
          <form @submit.prevent="submitPasswordChangeForm">
            <div class="account-block account-block-3 d-flex flex-wrap w-100">
              <div class="account-block-title d-flex flex-wrap w-100">
                <h3 class="fs-18 fw-600">Password</h3>
                <a href="javascript:void(0);" class="change-link change-password-link">Change</a>
              </div>
              <div class="account-block-content account-current-password">
                <div class="account-block-form d-flex flex-wrap w-100">
                  <div class="home-banner-form d-flex flex-wrap w-100">
                    <div class="text-box">
                      <label for="userpassProfile" class="active">Password<sup>*</sup></label>
                      <input id="userpassProfile" type="password" value="13245633333333" disabled />
                    </div>
                  </div>
                </div>

              </div>
              <div class="account-block-content account-curr-password">
                <div class="account-block-form d-flex flex-wrap w-100">
                  <div class="home-banner-form d-flex flex-wrap w-100">
                    <div class="text-box">
                      <label for="currPass" :class="{ 'active': currentPassword }">Enter Current Password<sup>*</sup></label>
                      <input id="currPass" type="password" v-model.trim="currentPassword" />
                      <span v-for="error in v$.currentPassword.$errors" :key="error.uid" class="label-error">{{
                        error.$message }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="account-block-content account-new-password">
                <div class="account-block-form d-flex flex-wrap w-100">
                  <div class="home-banner-form d-flex flex-wrap w-100">
                    <div class="text-box">
                      <label for="newpass" :class="{ 'active': newPassword }">Enter New Password<sup>*</sup></label>
                      <input id="newpass" type="password" v-model.trim="newPassword" />
                      <span v-for="error in v$.newPassword.$errors" :key="error.uid" class="label-error">{{
                        error.$message }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="account-block-content account-confirm-password">
                <div class="account-block-form d-flex flex-wrap w-100">
                  <div class="home-banner-form d-flex flex-wrap w-100">
                    <div class="text-box" :class="{ 'active': confirmPassword }">
                      <label for="confirmpass">Confirm New Password<sup>*</sup></label>
                      <input id="confirmpass" type="password" v-model.trim="confirmPassword" />
                      <span v-for="error in v$.confirmPassword.$errors" :key="error.uid" class="label-error">{{
                        error.$message }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="account-block-cta change-password-cta" style="flex:0 0 100%;max-width:100%;">
                <button type="submit" class="fg-btn-primary fg-btn-darkblue">
                  <span>Update</span>
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10 0.333496C10 1.39065 10.9167 2.97259 11.8434 4.30114C13.0367 6.01164 14.4608 7.50591 16.095 8.64678C17.3192 9.50107 18.8058 10.3211 20 10.3211M10 20.3335C10 19.2763 10.9167 17.6944 11.8434 16.3658C13.0367 14.6554 14.4608 13.1611 16.095 12.0202C17.3192 11.1659 18.8058 10.3458 20 10.3458M20 10.3335H0"
                      stroke="#fff" stroke-width="2" />
                  </svg>
                </button>
              </div>
            </div>
          </form>
          <!-- Password  -->
          <!-- Measurements -->
          <form @submit.prevent="submitMeasurementChangeForm">
            <div class="account-block d-flex flex-wrap w-100">
              <div class="account-block-title d-flex flex-wrap w-100">
                <h3 class="fs-18 fw-600">Your Measurements</h3>
                <a href="javascript:void(0);" class="change-link change-measurement-link">Change</a>
              </div>
              <div class="account-block-content account-block-content-2">
                <div class="account-block-form d-flex flex-wrap w-100">
                  <div class="home-banner-form d-flex flex-wrap w-100">
                    <div class="text-box">
                      <label for="userwaist" class="active">Waist<sup>*</sup></label>
                      <input id="userwaist" type="text" v-model="waist" disabled />
                      <span v-for="error in v$.waist.$errors" :key="error.uid" class="label-error">{{ error.$message
                        }}</span>
                    </div>
                    <div class="text-box">
                      <label for="userhips" class="active">Hips<sup>*</sup></label>
                      <input id="userhips" type="text" v-model="hip" disabled />
                      <span v-for="error in v$.hip.$errors" :key="error.uid" class="label-error">{{ error.$message
                        }}</span>
                    </div>
                    <div class="text-box">
                      <label for="userthigh" class="active">Thigh<sup>*</sup></label>
                      <input id="userthigh" type="text" v-model="thigh" disabled />
                      <span v-for="error in v$.thigh.$errors" :key="error.uid" class="label-error">{{ error.$message
                        }}</span>
                    </div>
                    <div class="text-box">
                      <label for="userinseam" class="active">Inseam<sup>*</sup></label>
                      <input id="userinseam" type="text" v-model="inseam" disabled />
                      <span v-for="error in v$.inseam.$errors" :key="error.uid" class="label-error">{{ error.$message
                        }}</span>
                    </div>
                  </div>
                </div>
                <div class="account-block-cta change-measurement-cta">
                  <button type="submit" class="fg-btn-primary fg-btn-darkblue">
                    <span>Update</span>
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10 0.333496C10 1.39065 10.9167 2.97259 11.8434 4.30114C13.0367 6.01164 14.4608 7.50591 16.095 8.64678C17.3192 9.50107 18.8058 10.3211 20 10.3211M10 20.3335C10 19.2763 10.9167 17.6944 11.8434 16.3658C13.0367 14.6554 14.4608 13.1611 16.095 12.0202C17.3192 11.1659 18.8058 10.3458 20 10.3458M20 10.3335H0"
                        stroke="#fff" stroke-width="2" />
                    </svg>
                  </button>
                </div>
              </div>

            </div>
          </form>
          <!-- Measurements -->
        </div>
      </div>
    </div>
  </section>
  <div class="goldies-top-picks-block goldies-top-picks-block-empty" style="max-width: 100%;">
    <div class="gtp-submit-retailer-block fs-16" style="border-radius: 0;">
      <p>Please contact <a href="mailto:fitgoldie@gmail.com" class="fg-btn-link submit-retailer-link">fitgoldie@gmail.com</a> to delete your account.</p>
    </div>
  </div>
  <measurement-block></measurement-block>
</template>

<script>
  import axios from "axios";
  import $ from "jquery";
  import { gsap, TimelineMax, ScrollTrigger } from "gsap/all";

  import { useVuelidate } from '@vuelidate/core';
  import { required, helpers, minLength, sameAs, between } from '@vuelidate/validators';

  import MeasurementBlock from "../components/layout/measurement/MeasurementBlock.vue";
  import TheMeasurement from "../components/layout/measurement/TheMesurement.vue";

  export default {
    components: {
      MeasurementBlock,
      TheMeasurement
    },
    data () {
      return {
        v$: useVuelidate(),
        email: "",
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        waist: "",
        hip: "",
        thigh: "",
        inseam: "",
        name: ""
      }
    },
    validations() {
      return {
        name: {
          required: helpers.withMessage('Name is required.', required)
        },
        currentPassword: {
          required: helpers.withMessage('Current password is required.', required)
        },
        newPassword: {
          required: helpers.withMessage('Password is required.', required),
          minLength: helpers.withMessage('Password should be at least 6 characters long.', minLength(6))
        },
        confirmPassword: {
          sameAsPassword: helpers.withMessage('Confirm password must be same as password.', sameAs(this.newPassword))
        },
        waist: {
          required: helpers.withMessage('Waist is required.', required),
          between: helpers.withMessage('Invalid value.', between(11, 99))
        },
        hip: {
          required: helpers.withMessage('Hips is required.', required),
          between: helpers.withMessage('Invalid value.', between(11, 99))
        },
        thigh: {
          required: helpers.withMessage('Thigh is required.', required),
          between: helpers.withMessage('Invalid value.', between(11, 99))
        },
        inseam: {
          required: helpers.withMessage('Inseam is required.', required),
          between: helpers.withMessage('Invalid value.', between(11, 99))
        }
      }
    },
    computed: {
      loggedInEmail: function () {
        return this.$store.getters.getEmail;
      },
      loggedInMeasurement: function () {
        return this.$store.getters['products/getMeasurementFromLocal'];
      },
      isLoggedIn: function() {
        return this.$store.getters.isAuth;
      },
      getToken: function () {
        return this.$store.getters.getToken;
      },
      getId: function () {
        return this.$store.getters.getUserId;
      },
      loggedInName: function () {
        return this.$store.getters.name;
      },
      measurementType: function () {
        return localStorage.getItem("measurementType");
      }
    },
    methods: {
      submitPasswordChangeForm: async function () {
        this.v$.currentPassword.$touch();
        this.v$.newPassword.$touch();
        this.v$.confirmPassword.$touch();
        const validateFormCurrentPassword = await this.v$.currentPassword.$validate();
        const validateFormNewPassword = await this.v$.newPassword.$validate();
        const validateFormConfirmPassword = await this.v$.confirmPassword.$validate();
        if (!validateFormCurrentPassword) return;
        if (!validateFormNewPassword) return;
        if (!validateFormConfirmPassword) return;

        if (this.isLoggedIn) {
          let headers = {};
          headers.Authorization = "Bearer " + this.getToken;
          const submitPasswordChange = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/account/change/password/from/setting`, {
            currentPassword: this.currentPassword,
            password: this.newPassword,
            confirmPassword: this.confirmPassword
          }, {
            headers
          });

          if (submitPasswordChange && submitPasswordChange.status === 200 && submitPasswordChange.data.code == 200) {
            this.$swal({
              position: "top",
              icon: false,
              title: false,
              html: "Your account has been reset. Please login again.",
              showConfirmButton: false,
              timer: 2100,
            });
            setTimeout(() => {
              this.$store.dispatch('logoutUser');
              // this.$router.push("/");
              this.$router
                .push({path: "/"})
                .then(() => { this.$router.go(0) });
            }, 2300);
            return;
          } else {
            return this.$swal({
              position: "top",
              icon: false,
              title: false,
              html: submitPasswordChange.data.message,
              showConfirmButton: false,
              timer: 1900,
            });
          }
        } else {
          alert("User is not logged In");
          return;
        }
      },
      submitMeasurementChangeForm: async function () {
        this.v$.waist.$touch();
        this.v$.hip.$touch();
        this.v$.thigh.$touch();
        this.v$.inseam.$touch();
        const validateFormWaist = await this.v$.waist.$validate();
        const validateFormHip = await this.v$.hip.$validate();
        const validateFormThigh = await this.v$.thigh.$validate();
        const validateFormInseam = await this.v$.inseam.$validate();
        console.log(this.v$);
        if (!validateFormWaist) return;
        if (!validateFormHip) return;
        if (!validateFormThigh) return;
        if (!validateFormInseam) return;

        if (this.isLoggedIn) {
          // alert()
          let headers = {};
          headers.Authorization = "Bearer " + this.getToken;
          const submitMeasurementChange = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/account/change/measurement`, {
            waist: this.waist,
            hip: this.hip,
            thigh: this.thigh,
            inseam: this.inseam
          }, {
            headers
          });

          if (submitMeasurementChange && submitMeasurementChange.status === 200 && submitMeasurementChange.data.code == 200) {
            this.$swal({
              position: "top",
              icon: false,
              title: false,
              html: submitMeasurementChange.data.message,
              showConfirmButton: false,
              timer: 1900,
            });

            this.$store.dispatch('products/measurementFromUser', {
              waist: this.waist,
              hip: this.hip,
              thigh: this.thigh,
              inseam: this.inseam,
              email: this.email,
              measurementType: this.measurementType ? this.measurementType : "inches"
            });
            setTimeout(() => {
              this.$router.go();
              return;
            }, 2100);
          } else {
            return this.$swal({
              position: "top",
              icon: false,
              title: false,
              html: "User is not logged In",
              showConfirmButton: false,
              timer: 1900,
            });
          }
        }
      },
      submitNameChangeForm: async function () {
        this.v$.name.$touch();
        const validateForm = await this.v$.name.$validate();
        if (!validateForm) return;
        if (this.isLoggedIn) {
          let headers = {};
          headers.Authorization = "Bearer " + this.getToken;
          const submitNameChange = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/account/change/name`, {
            name: this.name
          },  {
            headers
          });
          if (submitNameChange && submitNameChange.status === 200 && submitNameChange.data.code == 200){
            this.$swal({
              position: "top",
              icon: false,
              title: false,
              html: "Your account name has been changed successfully.",
              showConfirmButton: false,
              timer: 1900,
            });
            setTimeout(() => {
              this.$router.go();
              return;
            }, 2100);
            this.$store.dispatch('changeName', {
              _id: this.getId,
              token: this.getToken,
              name: this.name
            });
          } else {
            return this.$swal({
              position: "top",
              icon: false,
              title: false,
              html: submitNameChange.data.message,
              showConfirmButton: false,
              timer: 1900,
            });
          }
        } else {
          return this.$swal({
            position: "top",
            icon: false,
            title: false,
            html: "Please login.",
            showConfirmButton: false,
            timer: 1900,
          });
        }
      }
    },
    async mounted() {
      gsap.registerPlugin(TimelineMax);
      gsap.registerPlugin(ScrollTrigger);

      // Jquery stuff
      $(document).ready(function () {
        $('.measurement-btn').on('click', function () {
          $('.measurement-btn .arrow-icon').toggleClass('rotate');
        })

        // Define onStart function
        function onReverseCompleteFunction() {
          $('.measurement-btn .arrow-icon').removeClass('rotate');
        }
        function onStartFunction() {
          $('.measurement-btn .arrow-icon').addClass('rotate');
        }
        document.querySelector(".measurement-btn").addEventListener("click", animateIt);

        var tl = new TimelineMax();
        tl.to(".search-measurements-block", 0.1, {
          // y: 0,
          display: 'flex'
        })
          .to(".measurement-btn-block", 0.1, {
            marginTop: 0,
            //ease:Power3.easeIn,
            onStart: onStartFunction,// Assign onStart callback function
            onReverseComplete: onReverseCompleteFunction // Assign onStart callback function
          }, "<");
        tl.reverse(-1)
        tl.reversed(true);

        function animateIt() {
          tl.reversed(!tl.reversed());
        }
        /*Measurement button form toggle*/
        /*Measurements form toggle onscroll*/
        $(window).bind('scroll', function () {
          var scrollsec1 = $(window).scrollTop();
          var elementOffsetscrollsec1 = $('.sec-nav-blur').offset().top;
          var currentElementOffsetscrollsec1 = (elementOffsetscrollsec1 - scrollsec1);
          if (currentElementOffsetscrollsec1 < 5) {
            //console.log('second section reached top of screen');
            $('.fg-navigation .top-nav .container').addClass('nav-bg-blur');
            $('.measurement-block-onscroll .measurement-btn').fadeIn();
          } else {
            $('.fg-navigation .top-nav .container').removeClass('nav-bg-blur');
            $('.measurement-block-onscroll .measurement-btn').fadeOut();
            $('.mb-os-form').hide();
          }
        });
        $('.measurement-block-onscroll .measurement-btn').on('click', function () {
          $('.mb-os-form').fadeToggle(100);
          /* $('body').addClass('noscroll'); */
          if ($('.measurements-form-overlay').is(':visible')) {
            $('.measurements-form-overlay').hide();
          } else {
            $('.measurements-form-overlay').show();
          }
          if ($('body').hasClass('noscroll')) {
            $('body').removeClass('noscroll')
          } else {
            $('body').addClass('noscroll')
          }
        });

        /*Measurements form toggle onscroll*/
        const showMeasurementAnim = gsap
          .from(".measurement-block-onscroll", {
            opacity: 0,
            paused: true,
            duration: 0.1,
          })
          .progress(1);

        ScrollTrigger.create({
          start: "top top",
          end: 999999,
          onUpdate: (self) => {
            self.direction === -1 ? showMeasurementAnim.play() : showMeasurementAnim.reverse();
          },
        });
        /*Navigation to hide on scroll*/
        const showAnim = gsap
          .from(".top-nav", {
            yPercent: -150,
            paused: true,
            duration: 0.3,
          })
          .progress(1);

        ScrollTrigger.create({
          trigger: ".sec-nav-blur",
          start: "top 100",
          end: 999999,
          onUpdate: (self) => {
            self.direction === -1 ? showAnim.play() : showAnim.reverse();
          },
          // markers: true,
          // markers:{
          // 	startColor: '#f00',
          // 	endColor: '#9179CD',
          // 	fontSize: '2rem'
          // }
        });
        /*Navigation to hide on scroll*/
      })


      // console.log("document-----------------");
      // console.log(document.title);
      document.title = `Find Jeans That Actually Fit | Goldie`;
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', `Fit Goldie`);
      } else {
        const newMetaDescription = document.createElement('meta');
        newMetaDescription.setAttribute('name', "Fit Goldie");
        newMetaDescription.setAttribute('content', "Fit Goldie");
        document.head.appendChild(newMetaDescription);
      }
      // JQuery stuff
      $(".account-curr-password").hide();
      $(document).ready(function () {
        $('.change-email-link').on('click',function(){
          $('.change-email-cta').fadeIn();
          $('#useremail').prop("disabled", false);
        })
        $('.change-password-link').on('click',function(){
          $('.change-password-cta').fadeIn();
          $('.account-current-password').hide();
          $('.account-new-password, .account-confirm-password, .account-curr-password').show();
        })
        $('.change-measurement-link').on('click',function(){
          $('.change-measurement-cta').fadeIn();
          $('#userwaist, #userhips, #userthigh, #userinseam').prop("disabled", false);
        })
        $('.change-name-link').on('click',function(){
          $('.change-name-cta').fadeIn();
          $('#username').prop("disabled", false);
        })
      });

      // Populate data into page
      this.email = this.loggedInEmail;
      let measurement = this.loggedInMeasurement;
      this.waist = measurement.waist;
      this.hip = measurement.hip;
      this.thigh = measurement.thigh;
      this.inseam = measurement.inseam;
      this.name = this.loggedInName;
    }
  }
</script>

<style>
</style>
