<template>
  <section class="fg-section home-sec-1">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="home-banner-block d-flex flex-wrap w-100 offset-block">
            <div class="home-banner-content-wrapper">
              <div class="home-banner-content">
                <h1 class="home-banner-title font-koaly fs-88">{{ bannerTitle }}</h1>
                <div class="home-banner-desc text-white fs-20" v-html="bannerSubTitle">
                </div>
              </div>
            </div>
            <div class="home-banner-graphic">
              <!-- <img :src="`${baseUrl}${bannerImage}`" alt="" class="img-fluid"> -->
              <div id="home-banner-graphic"></div>
            </div>
            <div class="home-banner-form-block">
              <div class="home-banner-form-block-content">
                <div class="home-banner-form-block-title">
                  <h3 class="text-white fs-22 fw-800">Let's find your perfect fit!</h3>
                  <svg class="form-toggle-arrow" width="32" height="33" viewBox="0 0 32 33" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 20.3335L16 12.3335L8 20.3335" stroke="#F2C840" stroke-width="3" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </div>
                <div class="home-banner-form-toggle-wrapper">
                  <form @submit.prevent="submitMeasurementForm">
                    <div class="home-banner-measure-block d-flex flex-wrap w-100">
                      <div class="hbm-title fs-20">
                        <a href="javascript:void(0);" class="fg-btn-link fg-btn-link-gold how-to-measure">
                          <span>{{ bannerMeasure }}</span>
                          <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M8.49219 0.411499C8.49219 1.25722 9.22553 2.52277 9.96689 3.58562C10.9215 4.95402 12.0609 6.14943 13.3682 7.06213C14.3476 7.74556 15.5368 8.40162 16.4922 8.40162M8.49219 16.4115C8.49219 15.5658 9.22553 14.3002 9.96689 13.2374C10.9215 11.8691 12.0609 10.6736 13.3682 9.76087C14.3476 9.07744 15.5368 8.42138 16.4922 8.42138M16.4922 8.4115H0.492188"
                              stroke="#F2C840" stroke-width="2" />
                          </svg>
                        </a>
                      </div>
                      <div class="hbm-switch">
                        <label class="switch">
                          <!-- <input type="checkbox" v-model="measureIn" @change="measurementTypeChanged"> -->
                          <input type="checkbox" v-model="measureIn" @change="checkboxClick"/>
                          <div class="slider">
                            <span class="measure-inch">in</span>
                            <span class="measure-cm">cm</span>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="home-banner-form d-flex flex-wrap w-100">
                      <div class="text-box">
                        <label :class="{ 'active': waist }" for="waistHome">Waist<sup>*</sup></label>
                        <input id="waistHome" type="text" autocomplete="off" v-model="waist" />
                        <span v-for="error in v$.waist.$errors" :key="error.uid" class="label-error">{{ error.$message
                          }}</span>
                      </div>
                      <div class="text-box">
                        <label :class="{ 'active': hip }" for="hipsHome">Hips<sup>*</sup></label>
                        <input id="hipsHome" type="text" autocomplete="off" v-model="hip" />
                        <span v-for="error in v$.hip.$errors" :key="error.uid" class="label-error">{{ error.$message
                          }}</span>
                      </div>
                      <div class="text-box">
                        <label :class="{ 'active': thigh }" for="thighHome">Thigh<sup>*</sup></label>
                        <input id="thighHome" type="text" autocomplete="off" v-model="thigh" />
                        <span v-for="error in v$.thigh.$errors" :key="error.uid" class="label-error">{{ error.$message
                          }}</span>
                      </div>
                      <div class="text-box">
                        <label :class="{ 'active': inseam }" for="inseamHome">Inseam<sup>*</sup></label>
                        <input id="inseamHome" type="text" autocomplete="off" v-model="inseam" />
                        <span v-for="error in v$.inseam.$errors" :key="error.uid" class="label-error">{{ error.$message
                          }}</span>
                      </div>
                      <div class="text-box text-email">
                        <label :class="{ 'active': email }" for="useremailHome">Email<sup>*</sup></label>
                        <input id="useremailHome" type="text" v-model="email" />
                        <span v-for="error in v$.email.$errors" :key="error.uid" class="label-error">{{ error.$message
                          }}</span>
                      </div>
                      <div class="home-form-cta">
                        <button type="submit" class="fg-btn-primary w-100">
                          <span>Calculate My Matches</span>
                          <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M10 0.333496C10 1.39065 10.9167 2.97259 11.8434 4.30114C13.0367 6.01164 14.4608 7.50591 16.095 8.64678C17.3192 9.50107 18.8058 10.3211 20 10.3211M10 20.3335C10 19.2763 10.9167 17.6944 11.8434 16.3658C13.0367 14.6554 14.4608 13.1611 16.095 12.0202C17.3192 11.1659 18.8058 10.3458 20 10.3458M20 10.3335H0"
                              stroke="#0F2C66" stroke-width="2" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="home-banner-form-block-content scrolled">
								<div class="home-banner-form-block-title">
									<h3 class="text-white fs-22 fw-800">Let's find your perfect fit!</h3>
									<svg class="form-toggle-arrow" width="32" height="33" viewBox="0 0 32 33" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path d="M24 20.3335L16 12.3335L8 20.3335" stroke="#F2C840" stroke-width="3" stroke-linecap="round"
											stroke-linejoin="round" />
									</svg>
								</div>
								<div class="home-banner-form-toggle-wrapper aa">
									<form @submit.prevent="submitMeasurementForm">
										<div class="home-banner-measure-block d-flex flex-wrap w-100">
											<div class="hbm-title fs-20">
												<a href="javascript:void(0);" class="fg-btn-link fg-btn-link-gold how-to-measure">
													<span>{{ bannerMeasure }}</span>
													<svg width="17" height="17" viewBox="0 0 17 17" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M8.49219 0.411499C8.49219 1.25722 9.22553 2.52277 9.96689 3.58562C10.9215 4.95402 12.0609 6.14943 13.3682 7.06213C14.3476 7.74556 15.5368 8.40162 16.4922 8.40162M8.49219 16.4115C8.49219 15.5658 9.22553 14.3002 9.96689 13.2374C10.9215 11.8691 12.0609 10.6736 13.3682 9.76087C14.3476 9.07744 15.5368 8.42138 16.4922 8.42138M16.4922 8.4115H0.492188"
															stroke="#F2C840" stroke-width="2" />
													</svg>
												</a>
											</div>
											<div class="hbm-switch">
												<label class="switch">
													<input type="checkbox" v-model="measureIn" @change="checkboxClick">
													<div class="slider">
														<span class="measure-inch">in</span>
														<span class="measure-cm">cm</span>
													</div>
												</label>
											</div>
										</div>
										<div class="home-banner-form d-flex flex-wrap w-100">
											<div class="text-box">
												<label :class="{ 'active': waist }" for="waistHome">Waist<sup>*</sup></label>
                        <input id="waistHome" type="text" autocomplete="off" v-model="waist" />
                        <span v-for="error in v$.waist.$errors" :key="error.uid" class="label-error">{{ error.$message
                          }}</span>
											</div>
											<div class="text-box">
												<label :class="{ 'active': hip }" for="hipsHome">Hips<sup>*</sup></label>
                        <input id="hipsHome" type="text" autocomplete="off" v-model="hip" />
                        <span v-for="error in v$.hip.$errors" :key="error.uid" class="label-error">{{ error.$message
                          }}</span>
											</div>
											<div class="text-box">
												<label :class="{ 'active': thigh }" for="thighHome">Thigh<sup>*</sup></label>
                        <input id="thighHome" type="text" autocomplete="off" v-model="thigh" />
                        <span v-for="error in v$.thigh.$errors" :key="error.uid" class="label-error">{{ error.$message
                          }}</span>
											</div>
											<div class="text-box">
												<label :class="{ 'active': inseam }" for="inseamHome">Inseam<sup>*</sup></label>
                        <input id="inseamHome" type="text" autocomplete="off" v-model="inseam" />
                        <span v-for="error in v$.inseam.$errors" :key="error.uid" class="label-error">{{ error.$message
                          }}</span>
											</div>
											<div class="text-box text-email">
												<label :class="{ 'active': email }" for="useremailHome">Email<sup>*</sup></label>
                        <input id="useremailHome" type="text" v-model="email" />
                        <span v-for="error in v$.email.$errors" :key="error.uid" class="label-error">{{ error.$message
                          }}</span>
											</div>
											<div class="home-form-cta">
												<button type="submit" class="fg-btn-primary w-100">
													<span>Calculate My Matches</span>
													<svg width="20" height="21" viewBox="0 0 20 21" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M10 0.333496C10 1.39065 10.9167 2.97259 11.8434 4.30114C13.0367 6.01164 14.4608 7.50591 16.095 8.64678C17.3192 9.50107 18.8058 10.3211 20 10.3211M10 20.3335C10 19.2763 10.9167 17.6944 11.8434 16.3658C13.0367 14.6554 14.4608 13.1611 16.095 12.0202C17.3192 11.1659 18.8058 10.3458 20 10.3458M20 10.3335H0"
															stroke="#0F2C66" stroke-width="2" />
													</svg>
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import lottie from 'lottie-web';

import { useVuelidate } from '@vuelidate/core';
import { required, email, helpers, between } from '@vuelidate/validators';

export default {
  props: ['bannerTitle', 'bannerSubTitle', 'bannerImage', 'bannerMeasure'],
  data() {
    return {
      v$: useVuelidate(),
      measureIn: false,
      waist: "",
      hip: "",
      thigh: "",
      inseam: "",
      email: "",
      inchToCm: 2.54,
      cmToInch: 0.393701,
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  validations() {
    return {
      waist: this.waistValidation,
      hip: this.hipValidation,
      thigh: this.thighValidation,
      inseam: this.inseamValidation,
      email: {
        required: helpers.withMessage('Email is required.', required),
        email: helpers.withMessage('Invalid email address.', email),
      }
    }
  },
  mounted() {
    this.loadAnimation();
    let measurement = this.$store.getters['products/getMeasurementFromLocal'];
    this.waist = measurement.waist;
    this.hip = measurement.hip;
    this.thigh = measurement.thigh;
    this.inseam = measurement.inseam;
    this.email = measurement.email;
    this.measureIn = measurement.measurementType == "cms" ? true : false
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuth;
    },
    waistValidation: function () {
      if (this.measureIn === false) { // inches
        return {
          required: helpers.withMessage('Waist is required.', required),
          between: helpers.withMessage('Invalid value.', between(11, 99))
        };
      } else { // cms
        return {
          required: helpers.withMessage('Waist is required.', required),
          between: helpers.withMessage('Invalid value.', between(11, 161))
        };
      }
    },
    hipValidation: function () {
      if (this.measureIn === false) { // inches
        return {
          required: helpers.withMessage('Hip is required.', required),
          between: helpers.withMessage('Invalid value.', between(11, 99))
        };
      } else { // cms
        return {
          required: helpers.withMessage('Hip is required.', required),
          between: helpers.withMessage('Invalid value.', between(11, 161))
        };
      }
    },
    thighValidation: function () {
      if (this.measureIn === false) { // inches
        return {
          required: helpers.withMessage('Thigh is required.', required),
          between: helpers.withMessage('Invalid value.', between(11, 99))
        };
      } else { // cms
        return {
          required: helpers.withMessage('Thigh is required.', required),
          between: helpers.withMessage('Invalid value.', between(11, 161))
        };
      }
    },
    inseamValidation: function () {
      if (this.measureIn === false) { // inches
        return {
          required: helpers.withMessage('Inseam is required.', required),
          between: helpers.withMessage('Invalid value.', between(11, 99))
        };
      } else { // cms
        return {
          required: helpers.withMessage('Inseam is required.', required),
          between: helpers.withMessage('Invalid value.', between(11, 161))
        };
      }
    }
  },
  methods: {
    checkboxClick () {
      this.v$.$reset();
      return;
    },
    loadAnimation() {
      const container = document.getElementById('home-banner-graphic');
      if (!container) {
        console.log('Element with id "home-banner-graphic" not found.');
        return;
      }

      lottie.loadAnimation({
        container: container,
        renderer: 'svg',
        autoplay: true,
        loop: false,
        path: 'animations/homebanner.json', // Path to Lottie animation JSON file
      });
    },
    convertType: function (type, value) {
      if (type == "cm") {
        let cmValue = (value * this.inchToCm).toFixed(2);
        if (Math.abs(cmValue - Math.round(cmValue)) < 0.01) {
          return cmValue;
        } else {
          return Math.round(cmValue);
        }
      } else if (type == "inch") {
        let inchValue = (value * this.cmToInch).toFixed(2);
        if (Math.abs(inchValue - Math.round(inchValue)) < 0.01) {
          return inchValue;
        } else {
          return Math.round(inchValue);
        }
      }
    },
    measurementTypeChanged: function () {
      if (this.measureIn) {
        let waistVal = "";
        let hipVal = "";
        let thighVal = "";
        let inseamVal = "";

        if (this.waist) {
          waistVal = this.convertType("cm", parseFloat(this.waist));
        }
        if (this.hip) {
          hipVal = this.convertType("cm", parseFloat(this.hip));
        }
        if (this.thigh) {
          thighVal = this.convertType("cm", parseFloat(this.thigh));
        }
        if (this.thigh) {
          inseamVal = this.convertType("cm", parseFloat(this.inseam));
        }

        this.waist = waistVal;
        this.hip = hipVal;
        this.thigh = thighVal;
        this.inseam = inseamVal;
      } else {
        let waistVal = "";
        let hipVal = "";
        let thighVal = "";
        let inseamVal = "";

        if (this.waist) {
          waistVal = this.convertType("inch", parseFloat(this.waist));
        }
        if (this.hip) {
          hipVal = this.convertType("inch", parseFloat(this.hip));
        }
        if (this.thigh) {
          thighVal = this.convertType("inch", parseFloat(this.thigh));
        }
        if (this.thigh) {
          inseamVal = this.convertType("inch", parseFloat(this.inseam));
        }

        this.waist = waistVal;
        this.hip = hipVal;
        this.thigh = thighVal;
        this.inseam = inseamVal;
      }
    },
    submitMeasurementForm: async function () {
      this.v$.$touch();
      const validateForm = await this.v$.$validate();
      if (!validateForm) return;

      // this.$store.getters['pages/measurementFromUser'];
      this.$store.dispatch('products/measurementFromUser', {
        waist: parseFloat(this.waist),
        hip: parseFloat(this.hip),
        thigh: parseFloat(this.thigh),
        inseam: parseFloat(this.inseam),
        email: this.email,
        measurementType: this.measureIn ? "cms" : "inches"
      });
      window.location.href = "/products";

      if (this.isLoggedIn == false) {
        // call api for measurement
        const submitMeasurementNotLoggedIn = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/measurement/save`, {
          waist: this.waist,
          hip: this.hip,
          thigh: this.thigh,
          inseam: this.inseam,
          email: this.email
        });
        console.log(submitMeasurementNotLoggedIn);
      }
      return;
    }
  }
}
</script>

<style></style>
