<template>
  <section class="partner-sec-2 sec-nav-blur">
    <div class="container">
      <div class="row">
        <div class="col-12 position-relative">
          <img src="assets/images/star-pattern-horizontal.svg" alt="" class="partner-sec-2-pattern">
          <h2 class="fs-48 fw-600 text-center">{{ sectionTwoTitle }}</h2>
          <div class="partner-form-block d-flex flex-wrap w-100">
            <div class="pfb-left">
              <div class="pfb-left-content fs-20 fw-400" v-html="sectionTwoText">
              </div>
            </div>
            <div class="pfb-right">
              <div class="fs-24 fw-400 pfb-right-title" v-html="sectionTwoFormText">
              </div>
              <form @submit.prevent="submitContactForm">
                <div class="partner-form-block">
                  <div class="home-banner-form d-flex flex-wrap w-100">
                    <div class="text-box">
                      <label for="waist">Company Name<sup>*</sup></label>
                      <input id="company_name" type="text" v-model.trim="companyName" />
                      <span v-for="error in v$.companyName.$errors" :key="error.uid" class="label-error">{{ error.$message
                        }}</span>
                    </div>
                    <div class="text-box">
                      <label for="hipsTwo">Company Email<sup>*</sup></label>
                      <input id="hipsTwo" type="email" v-model.trim="companyEmail" />
                      <span v-for="error in v$.companyEmail.$errors" :key="error.uid" class="label-error">{{
                        error.$message }}</span>
                    </div>
                    <div class="text-box text-email">
                      <label for="useremailSection">Company Phone<sup>*</sup></label>
                      <input id="useremailSection" type="text" v-model.trim="companyPhone" />
                      <span v-for="error in v$.companyPhone.$errors" :key="error.uid" class="label-error">{{
                        error.$message }}</span>
                    </div>
                    <div class="text-box text-email">
                      <label for="useremail">Message<sup>*</sup></label>
                      <textarea v-model="message"></textarea>
                      <span v-for="error in v$.message.$errors" :key="error.uid" class="label-error">{{ error.$message
                        }}</span>
                    </div>
                    <div class="home-form-cta">
                      <button type="submit" class="fg-btn-primary fg-btn-primary-blue w-100">
                        <span>Submit</span>
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10 0.333496C10 1.39065 10.9167 2.97259 11.8434 4.30114C13.0367 6.01164 14.4608 7.50591 16.095 8.64678C17.3192 9.50107 18.8058 10.3211 20 10.3211M10 20.3335C10 19.2763 10.9167 17.6944 11.8434 16.3658C13.0367 14.6554 14.4608 13.1611 16.095 12.0202C17.3192 11.1659 18.8058 10.3458 20 10.3458M20 10.3335H0"
                            stroke="#fff" stroke-width="2" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <div class="partner-or-block">
                <span class="or-text fs-18 fs-400">OR</span>
              </div>
              <div class="partner-mail-block">
                <div class="fs-24 fw-400" v-html="sectionTwoTextThree">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

import { useVuelidate } from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';

export default {
  props: ['sectionTwoTitle', 'sectionTwoText', 'sectionTwoFormText', 'sectionTwoTextThree'],
  data () {
    return {
      v$: useVuelidate(),
      companyName: "",
      companyEmail: "",
      companyPhone: "",
      message: ""
    }
  },
  validations () {
    return {
      companyName: {
        required: helpers.withMessage('Company name is required.', required)
      },
      companyPhone: {
        required: helpers.withMessage('Company phone is required.', required)
      },
      message: {
        required: helpers.withMessage('Message is required.', required)
      },
      companyEmail: {
        required: helpers.withMessage('Company email is required.', required),
        email: helpers.withMessage('Invalid email address.', email),
      }
    }
  },
  methods: {
    submitContactForm: async function () {
      this.v$.$touch();
      const validateForm = await this.v$.$validate();
      if (!validateForm) return;

      let getToken = this.$store.getters.getToken;
      let headers = {};
      if (getToken) {
        headers.Authorization = "Bearer " + getToken;
      }
      const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/contact/save/partner`, {
        name: this.companyName,
        email: this.companyEmail,
        number: this.companyPhone,
        message: this.message
      }, {
        headers
      });
      if (response) {
        this.v$.$reset();
        this.companyName = "";
        this.companyEmail = "";
        this.companyPhone = "";
        this.message = "";
        return this.$swal({
          position: "top",
          icon: false,
          title: false,
          html: "Submitted successfully.",
          showConfirmButton: false,
          timer: 1900,
        });
      }
      return;
    }
  },
}
</script>

<style></style>
