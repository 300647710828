<template>
  <div class="popup-signup-login">
      <a href="javascript:void(0);" class="popup-signup-close">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <path d="M30 10L10 30M10 10L30 30" stroke="white" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round"></path>
        </svg>
      </a>
    <div class="signup-login-content">
      <div class="login-signup-logo">
        <img src="../../../assets/images/logo-fitgoldie.svg" alt="" class="login-signup-logo-img">
      </div>
      <h3 class="login-signup-title fs-28 fw-700 text-white">Start Your Journey.</h3>
      <the-signup></the-signup>
      <the-login></the-login>
      <forgot-password></forgot-password>
    </div>
  </div>
</template>

<script>
  import TheSignup from "./signup/TheSignup.vue";
  import TheLogin from "./login/TheLogin.vue";
  import ForgotPassword from "./forgotPassword/ForgotPassword.vue";

  export default {
    components: {
      TheSignup,
      TheLogin,
      ForgotPassword
    },
    data() {
      return {};
    }
  }
</script>

<style></style>