  <template>
    <div class="gtp-single goldie-product-block" :id="this._id" @mouseenter="showHoverBlock(this._id)"
      @mouseleave="hideHoverBlock(this._id)">
      <div class="gpb-content">
        <a href="javascript:void(0);" class="gpb-content-link">
          <img :src="`${baseUrl}${productImages[0]}`" alt="" class="goldie-product-list-thumbnail">
          <img v-if="iconShow" src="../../assets/images/goldies-pick-icon.svg" alt="" class="goldies-pick-icon-img">
          <div class="gpb-hover-block-wrapper" :class="{ 'hover-block': isHovered, 'hover-block-hidden': !isHovered }">
            <div class="gpb-hover-block">
              <div class="pdc-size-fit">
                <h4 class="fs-18 fs-600">Size & Fit Notes</h4>
                <div class="pdc-size-fit-single">
                  <div class="pdcsf-icon">
                    <img src="../../assets/images/pdc-waist-icon.svg" alt="">
                  </div>
                  <div class="pdcsf-text">
                    <p>Waist: {{ notes.waist || '' }}</p>
                  </div>
                </div>
                <div class="pdc-size-fit-single">
                  <div class="pdcsf-icon">
                    <img src="../../assets/images/pdc-hips-icon.svg" alt="">
                  </div>
                  <div class="pdcsf-text">
                    <p>Hips: {{ notes.hip || '' }}</p>
                  </div>
                </div>
                <div class="pdc-size-fit-single">
                  <div class="pdcsf-icon">
                    <img src="../../assets/images/pdc-thigh-icon.svg" alt="">
                  </div>
                  <div class="pdcsf-text">
                    <p>Thigh: {{ notes.thigh || '' }}</p>
                  </div>
                </div>
                <div class="pdc-size-fit-single">
                  <div class="pdcsf-icon">
                    <img src="../../assets/images/pdc-inseam-icon.svg" alt="">
                  </div>
                  <div class="pdcsf-text">
                    <p>Inseam: {{ notes.inseam || '' }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
        <div class="gpb-content-bottom">
          <a href="javascript:void(0);">
            <div class="gpb-content-row-1 d-flex flex-wrap w-100">
              <div class="gpb-brand">
                <p class="fs-18">Jean size: {{ fullSize }}</p>
              </div>
            </div>
            <div class="gpb-content-row-2 d-flex flex-wrap w-100">
              <div class="gpb-product-name">
                <h3 class="fs-22 fw-600">{{ productName }}
                </h3>
              </div>
            </div>
            <!-- <div class="gpb-content-row-3 d-flex flex-wrap w-100">
              <div class="gpb-product-size">
                <p class="fs-16">Your size: {{ fullSize }}</p>
              </div>
            </div> -->
          </a>
          <a href="javascript:void(0);" @click="bookmarkFav(_id)" class="gpb-wishlist"
            :class="{ 'active-wishlist': isBookmark }" :id="`${_id}-heart`"></a>
        </div>
      </div>
    </div>
  </template>

  <script>
  import $ from "jquery";
  import axios from "axios";

  export default {
    props: ["productName", "uniqueId", "from", "_id", "isBookmark", "brandName", "fullSize", "productImages", "size", "notesFromTopPicks", "price"],
    data () {
      return {
        iconShow: false,
        notes: {},
        isHovered: false,
        baseUrl: process.env.VUE_APP_BASE_URL
      }
    },
    mounted() {
      this.iconShow = false;
      if (this.from) {
        this.iconShow = true;
      }
      // this.iconShow = !!this.from;
    },
    computed: {
      getMeasurement: function () {
        return this.$store.getters['products/getMeasurementFromLocal'];
      }
    },
    methods: {
      bookmarkFav: async function (_id) {
        const isAuth = this.$store.getters.isAuth;
        if (isAuth) {
          let getToken = this.$store.getters.getToken;
          // console.log("getToken---", getToken);
          const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/account/bookmark/fav/${_id}`, {}, {
            headers: {
              Authorization: "Bearer " + getToken
            }
          });
          // console.log(response);
          const bookmarkRes = response.data.message;
          if (bookmarkRes == "Saved!") {
            $(`#${_id}-heart.gpb-wishlist`).addClass('active-wishlist');
            return;
          } else {
            console.log($(`#${_id}-heart`));
            $(`#${_id}-heart.gpb-wishlist`).removeClass('active-wishlist');
            return;
          }
        } else {
          this.$swal({
            position: "top",
            icon: false,
            title: false,
            html: "Please signup/login to bookmark a product.",
            showConfirmButton: false,
            timer: 1900,
          });
          $('body').addClass('noscroll');
          $('.popup-signup-login').css({ 'display': 'flex' });
          $('.signup-form-popup, #forgotpassword-form-popup').hide();
          $('#login-form-popup').show();
          return;
        }
      },
      showHoverBlock: async function (id) {
        this.isHovered = true;
        const getNotes = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/product/get/only/notes`, {
          productId: id,
          waist: this.getMeasurement.waist,
          hip: this.getMeasurement.hip,
          thigh: this.getMeasurement.thigh,
          inseam: this.getMeasurement.inseam
        });
        if (getNotes && getNotes.status == 200) {
          if (this.notesFromTopPicks) {
            this.notes.waist = this.notesFromTopPicks;
            this.notes.hip = this.notesFromTopPicks;
            this.notes.thigh = this.notesFromTopPicks;
          } else {
            this.notes.waist = getNotes.data.data.fitNotes.waistFitNotes;
            this.notes.hip = getNotes.data.data.fitNotes.hipFitNotes;
            this.notes.thigh = getNotes.data.data.fitNotes.thighFitNotes;
          }
          this.notes.inseam = getNotes.data.data.fitNotes.inseamFitNotes;
          // $(`#${id}`).find('.gpb-hover-block-wrapper').show();
        }
        return;
      },
      hideHoverBlock: async function () {
        this.isHovered = false;
        // $(`#${id}`).find('.gpb-hover-block-wrapper').hide();
        return;
      }
    }
  }
  </script>

<style>
.hover-block {
  display: block;
}

.hover-block-hidden {
  display: none;
}
</style>
