<template>
  <div id="signup-form-popup" class="signup-form-popup">
    <form @submit.prevent="submitSignUpForm">
      <div class="home-banner-form d-flex flex-wrap w-100">
        <div class="text-box">
          <label for="nameSignup">Name<sup>*</sup></label>
          <input id="nameSignup" type="text" v-model="name" @input="checkNameEmpty" />
          <span v-for="error in v$.name.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
        </div>
        <div class="text-box">
          <label for="useremailSignup">Email<sup>*</sup></label>
          <input id="useremailSignup" type="email" v-model.trim="email" @input="checkEmailEmpty" />
          <span v-for="error in v$.email.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
        </div>
        <div class="text-box">
          <label for="waistSignup">Waist<sup>*</sup></label>
          <input id="waistSignup" type="text" v-model.trim="waist" @input="checkWaistEmpty" />
          <span v-for="error in v$.waist.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
        </div>
        <div class="text-box">
          <label for="hipsSignup">Hips<sup>*</sup></label>
          <input id="hipsSignup" type="text" v-model.trim="hip" @input="checkHipEmpty" />
          <span v-for="error in v$.hip.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
        </div>
        <div class="text-box">
          <label for="thighSignup">Thigh<sup>*</sup></label>
          <input id="thighSignup" type="text" v-model.trim="thigh" @input="checkThighEmpty" />
          <span v-for="error in v$.thigh.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
        </div>
        <div class="text-box">
          <label for="inseamSignup">Inseam<sup>*</sup></label>
          <input id="inseamSignup" type="text" v-model.trim="inseam" @input="checkInseamEmpty" />
          <span v-for="error in v$.inseam.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
        </div>
        <div class="text-box">
          <label for="userpasswordSignup">Password<sup>*</sup></label>
          <input id="userpasswordSignup" type="password" v-model="password" @input="checkPasswordEmpty" />
          <span v-for="error in v$.password.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
        </div>
        <div class="text-box">
          <label for="userconfirmpassword">Confirm Password<sup>*</sup></label>
          <input id="userconfirmpassword" type="password" v-model="confirmPassword"
            @input="checkConfirmPasswordEmpty" />
          <span v-for="error in v$.confirmPassword.$errors" :key="error.uid" class="label-error">{{ error.$message
            }}</span>
        </div>
        <div class="signup-tnc-block">
          <div class="form-check">
            <input type="checkbox" id="tnc-signup" v-model="agreementTerms" @change="v$.agreementTerms.$touch()">
            <label for="tnc-signup" class="tnc-signup fs-16 text-white">I agree to the <a
                href="/terms-conditions" target="_blank">Terms and conditions</a> and <a target="_blank" href="/privacy-policy">Privacy
                Policy</a></label>
          </div>
        </div>
        <span v-for="error in v$.agreementTerms.$errors" :key="error.uid" class="label-error">{{ error.$message
          }}</span>
        <!-- <span v-if="!$v$.form.agreementTerms.sameAs" class="label-error">{{ error.$message }}</span> -->
        <div class="home-form-cta signup-form-cta">
          <button type="submit" class="fg-btn-primary w-100">
            <span>Create Account</span>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10 0.333496C10 1.39065 10.9167 2.97259 11.8434 4.30114C13.0367 6.01164 14.4608 7.50591 16.095 8.64678C17.3192 9.50107 18.8058 10.3211 20 10.3211M10 20.3335C10 19.2763 10.9167 17.6944 11.8434 16.3658C13.0367 14.6554 14.4608 13.1611 16.095 12.0202C17.3192 11.1659 18.8058 10.3458 20 10.3458M20 10.3335H0"
                stroke="#0F2C66" stroke-width="2" />
            </svg>
          </button>
        </div>
        <div class="signin-toggle-block fs-16">
          <span class="text-white">Already have an account?</span>
          <a href="javascript:void(0);" class="toggle-link-gold login-toggle-link">Sign In</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import $ from "jquery";
import { useVuelidate } from '@vuelidate/core';
import { required, email, helpers, between, sameAs, minLength } from '@vuelidate/validators';

export default {
  data() {
    return {
      v$: useVuelidate(),
      name: "",
      email: "",
      waist: "",
      hip: "",
      thigh: "",
      inseam: "",
      password: "",
      confirmPassword: "",
      agreementTerms: false,
      formIsValid: true
    }
  },
  validations () {
    return {
      name: {
        required: helpers.withMessage('Name is required.', required)
      },
      waist: {
        required: helpers.withMessage('Waist is required.', required),
        between: helpers.withMessage('Invalid value.', between(11, 99))
      },
      hip: {
        required: helpers.withMessage('Hips is required.', required),
        between: helpers.withMessage('Invalid value.', between(11, 99))
      },
      thigh: {
        required: helpers.withMessage('Thigh is required.', required),
        between: helpers.withMessage('Invalid value.', between(11, 99))
      },
      inseam: {
        required: helpers.withMessage('Inseam is required.', required),
        between: helpers.withMessage('Invalid value.', between(11, 99))
      },
      password: {
        required: helpers.withMessage('Password is required.', required),
        minLength: helpers.withMessage('Password should be at least 6 characters long.', minLength(6))
      },
      confirmPassword: {
        required: helpers.withMessage('Confirm password is required.', required),
        sameAsPassword: helpers.withMessage('Confirm password must be same as password.', sameAs(this.password))
      },
      agreementTerms: {
        required: helpers.withMessage('Please aggree to our terms & conditions before getting started.', required),
        sameAs: helpers.withMessage('Please aggree to our terms & conditions before getting started.', sameAs(true))
      },
      email: {
        required: helpers.withMessage('Email is required.', required),
        email: helpers.withMessage('Invalid email address.', email),
      }
    }
  },
  methods: {
    submitSignUpForm: async function () {
      this.formIsValid = true;
      this.v$.$touch();
      const validateForm = await this.v$.$validate();
      if (!validateForm) return;
      const signupResponse = await this.$store.dispatch('signupUser', {
        name: this.name,
        email: this.email,
        waist: this.waist,
        hip: this.hip,
        thigh: this.thigh,
        inseam: this.inseam,
        password: this.password,
      });
      if (signupResponse.code === 200) {
        this.$router.go();
        return;
      } else {
        return this.$swal({
          position: "top",
          icon: false,
          title: false,
          html: signupResponse.message,
          showConfirmButton: false,
          timer: 1900,
        });
      }
    }
  },
  mounted() {
    var self = this;
    $('.popup-signup-close').on('click',function(){
      self.v$.$reset();
      $('.popup-signup-login').hide();
      $('.popup-measurement').hide();
      $('.popup-submit-retailer').hide();
      $('.popup-feedback').hide();
      $('body').removeClass('noscroll');
    })
    $(document).on('keyup', function(e) {
      if (e.key == "Escape"){
        self.v$.$reset();
        $('.popup-signup-login').hide();
        $('.popup-measurement').hide();
        $('.popup-submit-retailer').hide();
        $('.popup-feedback').hide();
        $('body').removeClass('noscroll');
      }
    });

    $('.login-toggle-link').on('click',function(){
      self.v$.$reset();
      $('.signup-form-popup, #forgotpassword-form-popup').hide();
      $('#login-form-popup').fadeIn();
    });
    $('.forgot-toggle-link').on('click',function(){
      self.v$.$reset();
      $('.signup-form-popup,#login-form-popup').hide();
      $('#forgotpassword-form-popup').fadeIn();
    })
  }
}
</script>

<style>

</style>
