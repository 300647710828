<template>
  <section class="goldies-favorites-sec">
    <div class="container">
      <div class="col-12">
        <div class="row">
          <div class="goldies-favorites-title-block d-flex flex-wrap w-100">
            <h2 class="fs-48 fw-600 text-navyblue">Favorites</h2>
            <div class="title-border-block"></div>
            <div class="gf-slider-arrows-block slick-controls">
              <button class="slider-btn gf-slider-prev">
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16 32.3737C16 30.6822 14.5333 28.1511 13.0506 26.0254C11.1413 23.2886 8.86267 20.8978 6.24801 19.0724C4.28926 17.7055 1.91071 16.3934 2.04944e-07 16.3934M16 0.373655C16 2.0651 14.5333 4.5962 13.0506 6.72189C11.1413 9.45853 8.86267 11.8495 6.24801 13.6749C4.28926 15.0418 1.91071 16.3539 2.08399e-07 16.3539M2.06671e-07 16.3737L32 16.3737"
                    stroke="#1F5CD7" stroke-width="2" />
                </svg>
              </button>
              <button class="slider-btn gf-slider-next">
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16 0.373657C16 2.06511 17.4667 4.5962 18.9494 6.72189C20.8587 9.45869 23.1373 11.8495 25.752 13.6749C27.7107 15.0418 30.0893 16.3539 32 16.3539M16 32.3737C16 30.6822 17.4667 28.1511 18.9494 26.0254C20.8587 23.2888 23.1373 20.8978 25.752 19.0724C27.7107 17.7055 30.0893 16.3934 32 16.3934M32 16.3737H0"
                    stroke="#1F5CD7" stroke-width="2" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="godlies-favorites-content-block">
      <div class="goldies-favorites-slider">
        <product-card v-for="product in favProducts"
          :key="product._id"
          :productName="product.productName"
          :uniqueId="product.uniqueId"
          :brandName="product.brand.name"
          :fullSize="product.fullSize"
          :from="product.topPicks ? parseInt(product.topPicks) : 0"
          :_id="product._id"
          :isBookmark=1
          :size="product.size"
          :notesFromTopPicks="product.topPicks ? 'Fits just right' : 0"
          :productImages="product.productImages || []"
          @click="openProdDetailPopup(product._id)"
        ></product-card>
      </div>
    </div>
  </section>
</template>

<script>
  import $ from "jquery";
  import axios from "axios";
  import 'slick-carousel';
  import ProductCard from "../../ui/ProductCard.vue";

  export default {
    props: ["favProducts"],
    components: {
      ProductCard
    },
    data() {
      return {
        products: []
      };
    },
    methods: {
      openProdDetailPopup: async function (productId) {
        // Call api for product detail
        let measurement = this.$store.getters['products/getMeasurementFromLocal'];
        if (measurement && measurement.waist && measurement.hip && measurement.thigh && measurement.inseam) {
          let getToken = this.$store.getters.getToken;
          let headers = {};
          if (getToken) {
            headers.Authorization = "Bearer " + getToken;
          }
          axios.post(`${process.env.VUE_APP_API_BASE_URL}/product/detail`, {
            productId: productId,
            waist: measurement.waist,
            hip: measurement.hip,
            thigh: measurement.thigh,
            inseam: measurement.inseam
          }, {
            headers
          })
            .then((product) => {
              $(document).on('click', '.goldie-product-block a:not(.gpb-wishlist)', function () {
                // change URL
                window.history.pushState({}, '', `${process.env.VUE_APP_BASE_URL}/product/detail/${product.data.data.detail.slug}`);
              })
              this.emitter.emit("product-detail-data", {
                product: product.data.data.detail,
                fitNotes: product.data.data.fitNotes
              });
            })
        }
      },
    },
    async mounted() {
      /*Favories jeans section offset calculation*/
      let sectionLeft = document.querySelector(".offset-block");
      let sectionLeftOffset = sectionLeft.getBoundingClientRect();
      const sectionLeftOffsetVal = sectionLeftOffset.left;
      if ($(window).width() > 767) {
        $('.fjc-left').css({ 'padding-left': sectionLeftOffsetVal });
        $('.godlies-favorites-content-block').css({ 'margin-left': sectionLeftOffsetVal });
      }
      $(window).resize(function(){
        let sectionLeft = document.querySelector(".offset-block");
        if (sectionLeft != undefined) {
          let sectionLeftOffset = sectionLeft.getBoundingClientRect();
          const sectionLeftOffsetVal = sectionLeftOffset.left;
          if ($(window).width() > 767) {
            $('.fjc-left').css({ 'padding-left': sectionLeftOffsetVal });
          }
        }
      })
      /*Favories jeans section offset calculation*/
      // Favourites Slick
      $(".goldies-favorites-slider").slick({
        infinite: true,
        /* adaptiveHeight: true, */
        /* centerMode: true, */
        prevArrow: ".gf-slider-prev",
        nextArrow: ".gf-slider-next",
        cssEase: "ease-in-out",
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      });

      $(document).on('click', '.goldie-product-block a:not(.gpb-wishlist)', function(){
        $('.product-modal-content').addClass('open');
        $('.fg-navigation').addClass('reduceindex');
        //$('.measurements-form-overlay').show();
        $('body').addClass('noscroll');
      });
      $(document).on('click', '.pdc-icon', function(){
        $('.product-modal-content').removeClass('open');
        $('.fg-navigation').removeClass('reduceindex');
        //$('.measurements-form-overlay').hide();
        $('body').removeClass('noscroll');
      })
      /*Product details block hover*/
      // $(document).on("mouseenter", ".goldie-product-block", function() {
      //   $(this).children('.gpb-content').children('.gpb-content-link').children('.gpb-hover-block-wrapper').fadeIn(10);
      // });
      // $(document).on("mouseleave", ".goldie-product-block", function() {
      //   $(this).children('.gpb-content').children('.gpb-content-link').children('.gpb-hover-block-wrapper').fadeOut(10);
      // });
      /*Product details block hover*/
    },
  }
</script>

<style scoped>
  @import "../../../assets/css/slick.css";
  .goldies-favorites-slider .slick-slide .goldie-product-block{
    max-width:100%;
  }

</style>
