<template>
  <section class="measurement-sec-top about-page-sec-top hiw-sec-top">
    <!-- <img src="assets/images/how-goldie-works-banner-bg.svg" alt="" class="how-goldie-works-banner-img"> -->
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="home-banner-form search-measurements-block" :class="{'home-banner-email': showEmailInput}">
            <div class="text-box text-box-small">
              <label :class="{ 'active': waist }" for="waistBanner">Waist<sup>*</sup></label>
              <input id="waistBanner" type="text" autocomplete="off" v-model="waist" />
              <span v-for="error in v$.waist.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
            </div>
            <div class="text-box text-box-small">
              <label :class="{ 'active': hip }" for="hipsBanner">Hips<sup>*</sup></label>
              <input id="hipsBanner" type="text" v-model="hip" />
              <span v-for="error in v$.hip.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
            </div>
            <div class="text-box text-box-small">
              <label :class="{ 'active': thigh }" for="thighBanner">Thigh<sup>*</sup></label>
              <input id="thighBanner" type="text" autocomplete="off" v-model="thigh" />
              <span v-for="error in v$.thigh.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
            </div>
            <div class="text-box text-box-small">
              <label :class="{ 'active': inseam }" for="inseamBanner">Inseam<sup>*</sup></label>
              <input id="inseamBanner" type="text" autocomplete="off" v-model="inseam" />
              <span v-for="error in v$.inseam.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
            </div>
            <div class="text-box text-box-large" v-if="showEmailInput">
              <label :class="{ 'active': email }" for="emailBanner">Email<sup>*</sup></label>
              <input id="emailBanner" type="text" autocomplete="off" v-model="email" />
              <span v-for="error in v$.email.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
            </div>
            <div class="home-form-cta">
              <button type="submit" class="fg-btn-primary w-100" @click="submitMeasurementForm">
                <span v-if="measurementThere">Update</span>
                <span v-else>Calculate</span>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 0.333496C10 1.39065 10.9167 2.97259 11.8434 4.30114C13.0367 6.01164 14.4608 7.50591 16.095 8.64678C17.3192 9.50107 18.8058 10.3211 20 10.3211M10 20.3335C10 19.2763 10.9167 17.6944 11.8434 16.3658C13.0367 14.6554 14.4608 13.1611 16.095 12.0202C17.3192 11.1659 18.8058 10.3458 20 10.3458M20 10.3335H0"
                    stroke="#0F2C66" stroke-width="2" />
                </svg>
              </button>
            </div>
          </div>
          <div class="measurement-btn-block">
            <a href="javascript:void(0);" class="measurement-btn">
              <span v-if="isLoggedIn">Measurements </span>
              <span v-else>Let's find your perfect fit! </span>
              <svg class="arrow-icon" width="24" height="25" viewBox="0 0 24 25" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M6 9.22363L12 15.2236L18 9.22363" stroke="#F2C840" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </a>
          </div>
          <div class="about-banner-block">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="17" height="45" viewBox="0 0 17 45" fill="none"
              class="about-banner-icon about-banner-icon-1">
              <path
                d="M8.17656 0.0429688L8.6452 10.3724C9.20223 15.7423 11.2709 20.2439 15.7018 21.1234L16.7779 21.337L15.7018 21.5506C11.2709 22.4301 9.20223 26.5046 8.6452 31.8744L8.17656 44.3776L7.70792 31.8744C7.15089 26.5046 5.50699 22.2166 1.07604 21.337L0 21.1234L1.07604 20.9099C5.50699 20.0303 7.15089 15.7423 7.70792 10.3725L8.17656 0.0429688Z"
                fill="white" />
              <animate attributeName="opacity" dur="2s" values="0.2;1;0.2" repeatCount="indefinite" begin="0.1">
              </animate>
            </svg> -->
            <h1 class="fs-40 fw-400 text-white" v-html="bannerTitle"></h1>
            <svg width="57" height="83" viewBox="0 0 57 83" fill="none" xmlns="http://www.w3.org/2000/svg"
              class="about-banner-icon about-banner-icon-2">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M28.6883 5.69289L27.8868 13.1015C26.3155 27.6255 16.6657 39.2254 4.16989 41.6155C16.6657 44.0056 26.3155 55.6055 27.8868 70.1296L28.6883 77.5381L29.4898 70.1296C31.0611 55.6055 40.7109 44.0056 53.2067 41.6155C40.7109 39.2254 31.0611 27.6255 29.4898 13.1014L28.6883 5.69289ZM26.8907 12.9576L28.1902 0.945312L29.1864 0.945313L30.4859 12.9576C32.0031 26.9805 41.3246 38.1785 53.3927 40.4753L56.3766 41.0432L56.3766 42.1878L53.3927 42.7557C41.3246 45.0525 32.0031 56.2505 30.4859 70.2734L29.1864 82.2857H28.1902L26.8907 70.2734C25.3736 56.2505 16.052 45.0525 3.98395 42.7557L1 42.1878L1 41.0432L3.98395 40.4753C16.052 38.1785 25.3735 26.9805 26.8907 12.9576Z"
                fill="white" stroke="white" />
              <animate attributeName="opacity" dur="1.5s" values="0.5;1;0.5" repeatCount="indefinite" begin="0.1">
              </animate>
            </svg>
            <svg width="21" height="46" viewBox="0 0 21 46" fill="none" xmlns="http://www.w3.org/2000/svg"
              class="about-banner-icon about-banner-icon-3">
              <path
                d="M10.4375 0.464844L10.9892 12.1182C11.6449 18.1763 14.08 23.2549 19.2958 24.2472L20.5625 24.4882L19.2958 24.7291C14.08 25.7214 11.6449 30.3181 10.9892 36.3761L10.4375 45.1081L9.88585 36.3761C9.23014 30.3181 7.29503 25.4804 2.07915 24.4882L0.8125 24.2472L2.07915 24.0062C7.29504 23.014 9.23014 18.1763 9.88584 12.1183L10.4375 0.464844Z"
                fill="#0F2C66" />
              <animate attributeName="opacity" dur="2s" values="0.5;1;0.5" repeatCount="indefinite" begin="0.1">
              </animate>
            </svg>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import axios from "axios";
  import { useVuelidate } from '@vuelidate/core';
  import { required, helpers, between, email } from '@vuelidate/validators';

  export default {
    props: ["bannerTitle"],
    data () {
      return {
        v$: useVuelidate(),
        waist: "",
        hip: "",
        thigh: "",
        inseam: "",
        email: "",
        showEmailInput: true,
        measurementThere: false
      }
    },
    validations () {
      return {
        email: {
          required: helpers.withMessage('Email is required.', required),
          email: helpers.withMessage('Invalid email address.', email),
        },
        waist: this.waistValidation,
        hip: this.hipValidation,
        thigh: this.thighValidation,
        inseam: this.inseamValidation
      }
    },
    computed: {
      loggedInEmail: function () {
        return this.$store.getters.getEmail;
      },
      isLoggedIn: function() {
        return this.$store.getters.isAuth;
      },
      measurementType: function () {
        return localStorage.getItem("measurementType");
      },
      waistValidation: function () {
        if (this.measurementType === "inches") { // inches
          return {
            required: helpers.withMessage('Waist is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 99))
          };
        } else { // cms
          return {
            required: helpers.withMessage('Waist is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 161))
          };
        }
      },
      hipValidation: function () {
        if (this.measurementType === "inches") { // inches
          return {
            required: helpers.withMessage('Hip is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 99))
          };
        } else { // cms
          return {
            required: helpers.withMessage('Hip is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 161))
          };
        }
      },
      thighValidation: function () {
        if (this.measurementType === "inches") { // inches
          return {
            required: helpers.withMessage('Thigh is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 99))
          };
        } else { // cms
          return {
            required: helpers.withMessage('Thigh is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 161))
          };
        }
      },
      inseamValidation: function () {
        if (this.measurementType === "inches") { // inches
          return {
            required: helpers.withMessage('Inseam is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 99))
          };
        } else { // cms
          return {
            required: helpers.withMessage('Inseam is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 161))
          };
        }
      }
    },
    methods: {
      submitMeasurementForm: async function () {
        this.v$.$touch();
        const validateForm = await this.v$.$validate();
        if (!validateForm) return;
        // this.$store.getters['pages/measurementFromUser'];
        this.$store.dispatch('products/measurementFromUser', {
          waist: this.waist,
          hip: this.hip,
          thigh: this.thigh,
          inseam: this.inseam,
          email: this.email,
          measurementType: this.measurementType ? this.measurementType : "inches"
        });
        this.$router.push('/products');

        if (this.isLoggedIn == false) {
          // call api for measurement
          await axios.post(`${process.env.VUE_APP_API_BASE_URL}/measurement/save`, {
            waist: this.waist,
            hip: this.hip,
            thigh: this.thigh,
            inseam: this.inseam,
            email: this.email
          });
        }
        return;
      }
    },
    mounted() {
      let measurement = this.$store.getters['products/getMeasurementFromLocal'];
      this.waist = measurement.waist;
      this.hip = measurement.hip;
      this.thigh = measurement.thigh;
      this.inseam = measurement.inseam;
      this.email = measurement.email;

      if (this.waist && this.hip && this.thigh && this.inseam) {
        this.measurementThere = true;
      } else {
        this.measurementThere = false;
      }

      if (this.isLoggedIn) {
        this.showEmailInput = false;
      }
    }

  }
</script>

<style></style>
