  <template>
  <div class="measurements-form-overlay"></div>
  <the-measurement></the-measurement>
  <section class="product-detail-sec-1 sec-nav-blur">
    <div class="product-detail-content-block-wrapper">
      <div class="product-detail-content-block product-detail-page">
        <div class="pdc-left">
          <div class="pdc-left-mobile-productname">
            <div class="pdc-brand fs-16">
              <p>Jean size: {{ fullSize }}</p>
            </div>
            <h2 class="pdc-name fs-32 fw-600">{{ productName }}</h2>
            <!-- <div class="pdc-size fs-24">
              <p>Your Size: {{ fullSize }}</p>
            </div> -->
          </div>
          <div class="pdc-image-gallery">
            <div class="pdc-img-single" v-for="productImg in productImages" :key="productImg">
              <img :src="`${baseUrl}${productImg}`" alt="">
            </div>
          </div>
        </div>
        <div class="pdc-right">
          <div class="pdc-brand fs-16">
            <p>Jean size: {{ fullSize }}</p>
          </div>
          <h2 class="pdc-name fs-32 fw-600">{{ productName }}</h2>
          <!-- <div class="pdc-size fs-24">
            <p>Your Size: {{ fullSize }}</p>
          </div> -->
          <div class="pdc-cta d-flex flex-wrap w-100">
            <button class="fg-btn-primary fg-btn-primary-blue" @click="showNow(productUrl)">
              <span>Shop Now</span>
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.3545 0C12.3545 1.26859 13.4545 3.16691 14.5665 4.76118C15.9985 6.81377 17.7075 8.6069 19.6685 9.97595C21.1375 11.0011 22.9215 11.9852 24.3545 11.9852M12.3545 24C12.3545 22.7314 13.4545 20.8331 14.5665 19.2388C15.9985 17.1863 17.7075 15.3931 19.6685 14.0241C21.1375 12.9989 22.9215 12.0148 24.3545 12.0148M24.3545 12H0.354492"
                  stroke="white" stroke-width="2" />
              </svg>
            </button>
            <a href="javascript:void(0);" :id="`${this.id}-heart-pdc`" class="pdc-wishlist" :class="{'active-wishlist': bookmarked}" @click="bookmarkFav()"></a>
          </div>
          <div class="pdc-text-block">
            <div class="pdc-product-details">
              <h3 class=" fs-24 fw-600">Product Details</h3>
              <div class="pdc-product-details-text fs-16">
                <p>{{ productDesc }}</p>
              </div>
              <div class="rise-stretch-block">
                  <div class="rise-stretch-item">
                    <div class="pdcsf-icon">
                      <img src="../assets/images/high-rise-icon.svg" alt="">
                    </div>
                    <div class="pdcsf-text">
                      <p>{{ riseInProduct < 3 ? "Low Rise" : riseInProduct == 3 ? "Mid Rise" : riseInProduct > 3 ? "High Rise" : "Low Rise" }} </p>
                    </div>
                    <div class="pdcsf-indicator">
                      <div class="pdcsf-indicator-block">
                        <div class="pdfcsf-item pdfcsf-item-1" :class="{ 'active': riseInProduct == 1 }"></div>
                        <div class="pdfcsf-item pdfcsf-item-2" :class="{ 'active': riseInProduct == 2 }"></div>
                        <div class="pdfcsf-item pdfcsf-item-3" :class="{ 'active': riseInProduct == 3 }"></div>
                        <div class="pdfcsf-item pdfcsf-item-4" :class="{ 'active': riseInProduct == 4 }"></div>
                        <div class="pdfcsf-item pdfcsf-item-5" :class="{ 'active': riseInProduct == 5 }"></div>
                      </div>
                    </div>
                  </div>
                  <div class="rise-stretch-item">
                    <div class="pdcsf-icon">
                      <img src="../assets/images/low-stretch-icon.svg" alt="">
                    </div>
                    <div class="pdcsf-text">
                      <p>{{ stretch < 3 ? "Low Stretch" : stretch == 3 ? "Mid Stretch" : stretch > 3 ? "High Stretch" : "Low Stretch" }}</p>
                    </div>
                    <div class="pdcsf-indicator">
                      <div class="pdcsf-indicator-block">
                        <div class="pdfcsf-item pdfcsf-item-1" :class="{ 'active': stretch == 1 }"></div>
                        <div class="pdfcsf-item pdfcsf-item-2" :class="{ 'active': stretch == 2 }"></div>
                        <div class="pdfcsf-item pdfcsf-item-3" :class="{ 'active': stretch == 3 }"></div>
                        <div class="pdfcsf-item pdfcsf-item-4" :class="{ 'active': stretch == 4 }"></div>
                        <div class="pdfcsf-item pdfcsf-item-5" :class="{ 'active': stretch == 5 }"></div>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="pdc-size-fit">
                <h4 class="fs-18 fs-600">Size & Fit Notes</h4>
                <div class="pdc-size-fit-single">
                  <div class="pdcsf-icon">
                    <img src="../assets/images/pdc-waist-icon.svg" alt="">
                  </div>
                  <div class="pdcsf-text">
                    <p>Waist: {{ waistNotes }}</p>
                  </div>
                </div>
                <div class="pdc-size-fit-single">
                  <div class="pdcsf-icon">
                    <img src="../assets/images/pdc-hips-icon.svg" alt="">
                  </div>
                  <div class="pdcsf-text">
                    <p>Hips: {{ hipNotes }}</p>
                  </div>
                </div>
                <div class="pdc-size-fit-single">
                  <div class="pdcsf-icon">
                    <img src="../assets/images/pdc-thigh-icon.svg" alt="">
                  </div>
                  <div class="pdcsf-text">
                    <p>Thigh: {{ thighNotes }}</p>
                  </div>
                </div>
                <div class="pdc-size-fit-single">
                  <div class="pdcsf-icon">
                    <img src="../assets/images/pdc-inseam-icon.svg" alt="">
                  </div>
                  <div class="pdcsf-text">
                    <p>Inseam: {{ inseamNotes || "Normal" }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pdc-text-block">
            <h3 class="fs-24 fw-600">Disclaimer</h3>
            <div class="accordion-disclaimer">
              <div class="accordion-item active">
                <div class="accordion-header">{{ disclaimer.size }}</div>
                <div class="accordion-content" v-html="disclaimer.sizeDesc">
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header">{{ disclaimer.pricing }}</div>
                <div class="accordion-content" v-html="disclaimer.pricingDesc">
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header">{{ disclaimer.wash }}</div>
                <div class="accordion-content" v-html="disclaimer.washDesc">
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header">{{ disclaimer.availability }}</div>
                <div class="accordion-content" v-html="disclaimer.availabilityDesc">
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header">{{ disclaimer.returnPolicy }}</div>
                <div class="accordion-content" v-html="disclaimer.returnPolicyDesc">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <the-trigger @triggerIntersected="otherProducts"></the-trigger>
      <div class="other-matches-block" v-if="showRelatedProducts">
          <h2 class="fs-48 fw-600">Other fit matches</h2>
          <div class="rom-list-block related-products-block d-flex flex-wrap w-100">
            <div class="goldie-product-block" v-for="otherProduct in otherProductsArr" :key="otherProduct._id">
              <div class="gpb-content">
                <a :href="`/product/detail/${otherProduct.slug}`" class="gpb-content-link">
                  <img :src="`${baseUrl}${otherProduct.productImages[0]}`" alt="" class="goldie-product-list-thumbnail">
                  <!-- <img src="../assets/images/goldies-pick-icon.svg" alt="" class="goldies-pick-icon-img"> -->
                </a>
                <div class="gpb-content-bottom">
                  <a :href="`/product/detail/${otherProduct.slug}`">
                    <div class="gpb-content-row-1 d-flex flex-wrap w-100">
                      <div class="gpb-brand">
                        <p class="fs-18">Jean size: {{ otherProduct.size && otherProduct.size.sizeNumber ? otherProduct.size.sizeNumber : 0 }}</p>
                      </div>
                    </div>
                    <div class="gpb-content-row-2 d-flex flex-wrap w-100">
                      <div class="gpb-product-name">
                        <h3 class="fs-22 fw-600">{{ otherProduct.productName }}</h3>
                      </div>
                    </div>
                    <!-- <div class="gpb-content-row-3 d-flex flex-wrap w-100">
                      <div class="gpb-product-size">
                        <p class="fs-16">Your size:  28W x 32L</p>
                      </div>
                    </div> -->
                  </a>
                  <a href="javascript:void(0);" :id="`${otherProduct._id}-heart-other-det`" :class="{ 'active-wishlist': otherProduct.isBookmark }" @click="bookmarkFavOtherProduct(otherProduct._id)" class="gpb-wishlist"></a>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </section>
  <measurement-block></measurement-block>
  </template>

  <script>
  import $ from "jquery";
  import { gsap, ScrollTrigger, TimelineMax } from "gsap/all";
  import axios from "axios";

  import TheTrigger from "../components/layout/products/TheTrigger.vue";

  import TheMeasurement from "../components/layout/measurement/TheMesurement.vue";
  import MeasurementBlock from "../components/layout/measurement/MeasurementBlock.vue";

  export default {
    props: ["productSlug"],
    components: {
      TheMeasurement,
      MeasurementBlock,
      TheTrigger
    },
    data () {
      return {
        id: "",
        isBookmark: 0,
        productName: "",
        productImages: "",
        brandName: "",
        fullSize: "",
        productUrl: "",
        productDesc: "",
        waistNotes: "",
        hipNotes: "",
        thighNotes: "",
        inseamNotes: "",
        riseInProduct: "",
        stretch: "",
        disclaimer: {},
        showFeedback: false,
        otherProductsArr: [],
        showRelatedProducts: true,
        baseUrl: process.env.VUE_APP_BASE_URL
      }
    },
    computed: {
      bookmarked: function () {
        return this.isBookmark;
      },
      loggedInEmail: function () {
        return this.$store.getters.getEmail;
      },
      isAuth: function () {
        return this.$store.getters.isAuth;
      },
      getMeasurementData: function () {
        return this.$store.getters['products/getMeasurementFromLocal'];
      },
      getToken: function () {
        return this.$store.getters.getToken;
      }
    },
    methods: {
      checkForReturningUser: async function () {
        let getToken = this.$store.getters.getToken;
        if (getToken) {
          const checkSent = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/feedback/check/sent`, {
            userId: this.$store.getters.getUserId
          }, {
            headers: {
              Authorization: "Bearer " + getToken
            }
          });

          if (checkSent && checkSent.status === 200 && checkSent.data.code == 200) {
            const finResponse = checkSent.data.data
            if (!finResponse.feedbackThere) {
              localStorage.setItem('visitedBefore', true);
              // call api to save this
              const isAuth = this.$store.getters.isAuth;
              if (isAuth) {
                await axios.post(`${process.env.VUE_APP_API_BASE_URL}/feedback/send?pending=1`, {
                  email: this.loggedInEmail
                }, {
                  headers: {
                    Authorization: "Bearer " + getToken
                  }
                });
              }
            }
          }
        }
        return;
      },
      clickCapture: async function (id) {
        let getToken = this.$store.getters.getToken;
        let headers = {};
        if (getToken) {
          headers = { Authorization: "Bearer " + getToken };
        }
        await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/clickcapture/add`,
          {
            productId: id,
          },
          { headers }
        );
        return;
      },
      showNow: function (productUrl) {
        this.checkForReturningUser();
        this.clickCapture(this.id);
        window.open(productUrl, '_blank');
        return;
      },
      bookmarkFav: async function () {
        const isAuth = this.$store.getters.isAuth;
        if (isAuth) {
          let getToken = this.$store.getters.getToken;
          // console.log("getToken---", getToken);
          const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/account/bookmark/fav/${this.id}`, {}, {
            headers: {
              Authorization: "Bearer " + getToken
            }
          });
          const bookmarkRes = response.data.message;
          if (bookmarkRes == "Saved!") {
            $(`#${this.id}-heart-pdc`).toggleClass('active-wishlist');
            return;
          } else {
            $(`#${this.id}-heart-pdc`).toggleClass('active-wishlist');
            return;
          }
        } else {
          this.$swal({
            position: "top",
            icon: false,
            title: false,
            html: "Please login to bookmark a product.",
            showConfirmButton: false,
            timer: 1900,
          });
          $('body').addClass('noscroll');
          $('.popup-signup-login').css({ 'display': 'flex' });
          $('.signup-form-popup, #forgotpassword-form-popup').hide();
          $('#login-form-popup').show();
          return;
        }
      },
      otherProducts: async function () {
        // alert();
        this.showRelatedProducts = true;
        if (!this.getMeasurementData || !this.getMeasurementData.waist || !this.getMeasurementData.hip || !this.getMeasurementData.thigh || !this.getMeasurementData.inseam) {
          this.$swal({
            position: "top",
            icon: false,
            title: false,
            html: "Please submit your measurement form first.",
            showConfirmButton: false,
            timer: 1900,
          });
          setTimeout(() => {
            this.$router.push("/");
          }, 1700);
          return;
        }

        // Call api for next 3
        const headers = this.getToken ? { Authorization: "Bearer " + this.getToken } : {};
        const products = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/product/get/next/3`, {
          waistInput: parseFloat(this.getMeasurementData.waist),
          hipInput: parseFloat(this.getMeasurementData.hip),
          thighInput: parseFloat(this.getMeasurementData.thigh),
          inseamInput: parseFloat(this.getMeasurementData.inseam),
          productId: this.id
        }, {
          headers
        });

        if (products && products.status == 200 && products.data.code == 200) {
          this.otherProductsArr = products.data.data.products;
        } else {
          this.showRelatedProducts = false;
        }
        return;
      },
      bookmarkFavOtherProduct: async function (_id) {
        if (this.isAuth) {
          const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/account/bookmark/fav/${_id}`, {}, {
            headers: {
              Authorization: "Bearer " + this.getToken
            }
          });
          const bookmarkRes = response.data.message;
          if (bookmarkRes == "Saved!") {
            $(`#${_id}-heart-other-det`).addClass('active-wishlist');
            return;
          } else {
            $(`#${_id}-heart-other-det`).removeClass('active-wishlist');
            return;
          }
        } else {
          this.$swal({
            position: "top",
            icon: false,
            title: false,
            html: "Please login to bookmark a product.",
            showConfirmButton: false,
            timer: 1900,
          });
          $('body').addClass('noscroll');
          $('.popup-signup-login').css({ 'display': 'flex' });
          $('.signup-form-popup, #forgotpassword-form-popup').hide();
          $('#login-form-popup').show();
          return;
        }
      }
    },
    async mounted() {
      // Jquery Stuff
      /*Measurement button form toggle*/
      $('.measurement-btn').on('click', function () {
        $('.measurement-btn .arrow-icon').toggleClass('rotate');
      })

      // Define onStart function
      function onReverseCompleteFunction() {
        $('.measurement-btn .arrow-icon').removeClass('rotate');
      }
      function onStartFunction() {
        $('.measurement-btn .arrow-icon').addClass('rotate');
      }
      document.querySelector(".measurement-btn").addEventListener("click", animateIt);

      var tl = new TimelineMax();
      tl.to(".search-measurements-block", 0.1, {
        // y: 0,
        display: 'flex'
      })
        .to(".measurement-btn-block", 0.1, {
          marginTop: 0,
          onStart: onStartFunction,// Assign onStart callback function
          onReverseComplete: onReverseCompleteFunction // Assign onStart callback function
        }, "<");
      tl.reverse(-1)
      tl.reversed(true);

      function animateIt() {
        tl.reversed(!tl.reversed());
      }
      /*Measurement button form toggle*/

      /*Measurements form toggle onscroll*/
      $(window).bind('scroll', function() {
        var scrollsec1 = $(window).scrollTop();
        var elementOffsetscrollsec1 = $('.sec-nav-blur').offset().top;
        var currentElementOffsetscrollsec1 = (elementOffsetscrollsec1 - scrollsec1);
        if(currentElementOffsetscrollsec1 < 5){
          //console.log('second section reached top of screen');
          $('.fg-navigation .top-nav .container').addClass('nav-bg-blur');
          $('.measurement-block-onscroll .measurement-btn').fadeIn();
        }else{
          $('.fg-navigation .top-nav .container').removeClass('nav-bg-blur');
          $('.measurement-block-onscroll .measurement-btn').fadeOut();
          $('.mb-os-form').hide();
        }
      });
      $('.measurement-block-onscroll .measurement-btn').on('click',function(){
          $('.mb-os-form').fadeToggle(100);
          /* $('body').addClass('noscroll'); */
          if($('.measurements-form-overlay').is(':visible')){
            $('.measurements-form-overlay').hide();
          }else{
            $('.measurements-form-overlay').show();
          }
          if($('body').hasClass('noscroll')){
            $('body').removeClass('noscroll')
          }else{
            $('body').addClass('noscroll')
          }
        });
      /*Measurements form toggle onscroll*/
      /*Measurements button to hide on scroll*/
      const showMeasurementAnim = gsap
        .from(".measurement-block-onscroll", {
          opacity: 0,
          paused: true,
          duration: 0.1,
        })
        .progress(1);

      ScrollTrigger.create({
        start: "top top",
        end: 999999,
        onUpdate: (self) => {
          self.direction === -1 ? showMeasurementAnim.play() : showMeasurementAnim.reverse();
        },
      });
      /*Measurements button to hide on scroll*/
      /*Navigation to hide on scroll*/
      const showAnim = gsap
        .from(".top-nav", {
          yPercent: -150,
          paused: true,
          duration: 0.3,
        })
        .progress(1);

        ScrollTrigger.create({
          trigger:".sec-nav-blur",
          start: "top 100",
          end: 999999,
          onUpdate: (self) => {
            self.direction === -1 ? showAnim.play() : showAnim.reverse();
          },
          // markers: true,
          // markers:{
          // 	startColor: '#f00',
          // 	endColor: '#9179CD',
          // 	fontSize: '2rem'
          // }
        });
        /*Navigation to hide on scroll*/

      /*Product details block - related products slider on mobile*/
      if ($(window).width() < 767) {
        $('.related-products-block').slick({
          infinite: true,
          adaptiveHeight: true,
          arrows: false, // Disable arrows
          autoplay: false,
          autoplaySpeed: 1000, // Set the interval between slide transitions in milliseconds
          cssEase: "ease-in-out",
          slidesToShow: 1,
          dots: false,
        });
      }
      /*Product details block - related products slider on mobile*/

      /*Product details block - Goldies top picks slider mobile screens*/
      if ($(window).width() < 767) {
        $('.goldies-top-picks-block').slick({
          infinite: true,
          adaptiveHeight: true,
          arrows: false, // Disable arrows
          autoplay: false,
          autoplaySpeed: 1000, // Set the interval between slide transitions in milliseconds
          cssEase: "ease-in-out",
          slidesToShow: 1,
          dots: false,
        });
        $('.btn-filter').on('click',function(){
          $('.fg-results-filter-block').css("transform","translateX(0)");
          $('.measurements-form-overlay').fadeIn(100);
          $('body').addClass('noscroll');
        });
        $('.filter-block-close').on('click',function(){
          $('.fg-results-filter-block').css("transform","translateX(-100%)");
          $('.measurements-form-overlay').fadeOut(100);
          $('body').removeClass('noscroll');
        })
      }
      /*Product details block - Goldies top picks slider mobile screens*/

      // Diclaimer Accordians
      const accordionItems = document.querySelectorAll('.accordion-item');

      accordionItems.forEach(item => {
        const header = item.querySelector('.accordion-header');
        header.addEventListener('click', () => {
          // Close all sections
          accordionItems.forEach(otherItem => {
            if (otherItem !== item) {
              otherItem.classList.remove('active');
            }
          });
          // Toggle current section
          item.classList.toggle('active');
        });
      });

      // Call api for prioduct detail page
      let measurement = this.$store.getters['products/getMeasurementFromLocal'];
      if (measurement && measurement.waist && measurement.hip && measurement.thigh && measurement.inseam) {
        let getToken = this.$store.getters.getToken;
        let headers = {};
        if (getToken) {
          headers.Authorization = "Bearer " + getToken;
        }
        const productPromise = axios.post(`${process.env.VUE_APP_API_BASE_URL}/product/detail/page?productSlug=${this.productSlug}`, {
          waist: measurement.waist,
          hip: measurement.hip,
          thigh: measurement.thigh,
          inseam: measurement.inseam
        }, {
          headers
        });

        const disclaimerResponsePromise = axios.get(`${process.env.VUE_APP_API_BASE_URL}/common/disclaimer`);

        const [product, disclaimerResponse] = await Promise.all([productPromise, disclaimerResponsePromise]);

        // console.log("PRODUCT PAGE__________________________");
        // console.log(product);

        this.disclaimer = disclaimerResponse.data.data;

        if (product && product.status === 200 && product.data.code == 200) {
          let productResponse = product.data.data.detail;
          let notesResponse = product.data.data.fitNotes;
          document.title = `${productResponse.metaTitle} | Fit Goldie`;
          const metaDescription = document.querySelector('meta[name="description"]');
          if (metaDescription) {
            metaDescription.setAttribute('content', `${productResponse.metaDesc}`);
          } else {
            const newMetaDescription = document.createElement('meta');
            newMetaDescription.setAttribute('name', `${productResponse.metaDesc}`);
            newMetaDescription.setAttribute('content', `${productResponse.metaDesc}`);
            document.head.appendChild(newMetaDescription);
          }

          this.id = productResponse._id;
          this.isBookmark = productResponse.isBookmark;
          this.productName = productResponse.productName;
          this.productImages = productResponse.productImages;
          this.brandName = productResponse.brand.name;
          this.fullSize = productResponse.fullSize;
          this.productUrl = productResponse.productUrl;
          this.productDesc = productResponse.productDesc;
          this.waistNotes = notesResponse.waistFitNotes;
          this.hipNotes = notesResponse.hipFitNotes;
          this.thighNotes = notesResponse.thighFitNotes;
          this.inseamNotes = notesResponse.inseamFitNotes;
          this.riseInProduct = productResponse.rise;
          this.stretch = productResponse.stretch;
        } else {
          alert("Something went wrong!");
          return;
        }
      } else {
        this.$swal({
          position: "top",
          icon: false,
          title: false,
          html: "Please login for measurement or update the measurement.",
          showConfirmButton: false,
          timer: 1900,
        });
        setTimeout(() => {
          this.$router.push("/");
        }, 2500);
        return;
      }
    }
  }
  </script>

  <style scoped>

  </style>
